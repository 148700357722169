/**
 * @file Remplissage de la lettre de motivation
 * @author Craspouille
 */
import React, { useContext } from "react";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage.jsx";
import { ParagraphLetterDetails } from "./ParagraphLetterDetails.jsx";

export function LetterContentUpdated() {
  const { handleClickPanelVisibility, emplDetails } = useContext(ModifyContext);

  return (
    <div
      className="container-paragraph"
      onPointerUp={handleClickPanelVisibility}
    >
      <ParagraphLetterDetails />
    </div>
  );
}
