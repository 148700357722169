import React from "react";

export const Picture = ({ image, svg, ...rest }) => {
  /*
    Render a regular image/icon (jpeg, png, ...) OR a SVG.
    If both image and SVG are provided the SVG prevails.
  */

  let picture = null; // any image or SVG

  if (image && image.src && image.alt) {
    picture = <img alt={image.alt} src={image.src} {...rest} />;
  }
  if (svg) {
    picture = svg;
  }

  return picture;
}; // end of function component Picture
