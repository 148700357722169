import React from "react";

import { Picture } from "./Picture.jsx";

import "../scss/picture-box.scss";

export const PictureBox = ({
  cssClassName = "picture-box",
  image,
  svg,
  ...xprops
}) => {
  /*
    No rendering unless an image or a svg is provided.
  */

  return (
    (image || svg) && (
      <div className={cssClassName}>
        <Picture image={image} svg={svg} />
      </div>
    )
  );
}; // end of function component PictureBox
