/**
 * @file Information de l'emploteur
 * @author Craspouille
 */
import { useContext } from "react";

/* Context */

import { ModifyContext } from "../../../pages/LetterPage";
import { getOkayClass } from "../../../utils";

export function CompanyDetails() {
  const { companyDetails, companyForm, handleClickPanelVisibility } =
    useContext(ModifyContext);

  return (
    <div
      className="employer"
      onClick={handleClickPanelVisibility}
      ref={companyForm}
      data-steppers={2}
    >
      <p data-step={0}>
        <span className={getOkayClass(companyDetails["enterprise"])}>
          {companyDetails["enterprise"] === null ||
          companyDetails["enterprise"] === ""
            ? "Nom de l'entreprise"
            : companyDetails["enterprise"]}
        </span>
      </p>
      <p data-step={1}>
        <span className={getOkayClass(companyDetails["address"])}>
          {companyDetails["address"] === null ||
          companyDetails["address"] === ""
            ? "Adresse de l'entreprise"
            : companyDetails["address"]}
        </span>
      </p>
      <p data-step={1}>
        <span className={getOkayClass(companyDetails["zipcode"])}>
          {companyDetails["zipcode"] === null ||
          companyDetails["zipcode"] === ""
            ? "Code postal"
            : companyDetails["zipcode"]}
        </span>
        <span className={getOkayClass(companyDetails["city"])}>
          {companyDetails["city"] === null || companyDetails["city"] === ""
            ? "Ville"
            : companyDetails["city"]}
        </span>
      </p>
    </div>
  );
}
