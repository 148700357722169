import React from "react";

export function CrossSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      role="img"
      aria-label="Icône fermeture"
      title="Icône fermeture"
    >
      <g>
        <polygon
          strokeLinecap="round"
          points="56.971,52.728 44.243,40 56.971,27.272 52.728,23.029 40,35.757 27.272,23.029 23.029,27.272 35.757,40   23.029,52.728 27.272,56.971 40,44.243 52.728,56.971 "
        />
      </g>
    </svg>
  );
} // end function CrossSVG
