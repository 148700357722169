/**
 * @file Questionnaire identité de l'utilisateur
 * @author Craspouille
 */
import { useContext, useRef } from "react";

/* Button */
import { VersatileButton } from "../../VersatileButton.jsx";
/* SVG */
import { RightArrowSVG } from "../../svg/RightArrowSVG.jsx";

/* Component Form */
import { UserFormAddress } from "./UserFormAddress.jsx";
import { UserFormEmailPhone } from "./UserFormEmailPhone.jsx";
import { UserFormIdentity } from "./UserFormIdentity.jsx";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage.jsx";

export function UserForm() {
  const {
    step,
    setStep,
    handleFormFieldUpdate,
    canGoNext,
    setCanGoNext,
    setNextStep,
    nextForm, // Passage au formulaire suivant
  } = useContext(ModifyContext);

  const formulaire = useRef(null);

  function checkValidities(e) {
    // console.log("%c== check user : %s ==", `color: ${formulaire.current.checkValidity() === true ? 'lime' : 'deeppink'}; background: black; padding: 1em;`, formulaire.current.checkValidity());
    if (formulaire.current.checkValidity()) {
      setCanGoNext(step >= details.length - 1);
    } else {
      setCanGoNext(false);
    }
  }

  /* Liste des formulaires */
  const details = [
    <UserFormIdentity setstep={setStep} />,
    <UserFormAddress setstep={setStep} />,
    <UserFormEmailPhone setstep={setStep} />,
  ];

  /* Paramètres des boutons de navigations */
  //   let prevParams = {
  //     ...(step === 0 && { disabled: true }),
  //   };

  /* Fonctions des boutons de navigations */
  function handleNext() {
    if (step < details.length) {
      setStep(step + 1);
      setNextStep(false);
    }
  }

  function handlePrev() {
    if (step > 0) setStep(step - 1);
  }

  /**
   * Inhiber le comportement par défaut du questionnaire
   * @param {SubmitEvent} e
   */
  function onSubmit(e) {
    e.preventDefault();
  }

  return (
    <form
      method="POST"
      encType="multipart/form-data"
      id="usrForm"
      className="usrForm"
      ref={formulaire}
      onSubmit={onSubmit}
      onInput={handleFormFieldUpdate}
      onChange={checkValidities}
    >
      {details[step]}
      <div className="form-widget-steps">
        {step > 0 && (
          <VersatileButton
            content={"Précédent"}
            cssClass="previous-button"
            leftIcon={<RightArrowSVG />}
            onClickCallback={handlePrev}
          />
        )}
        {!canGoNext && step < details.length - 1 && (
          <VersatileButton
            content={"Suivant"}
            role="Button"
            cssClass="next-button"
            onClickCallback={handleNext}
            rightIcon={<RightArrowSVG />}
          />
        )}
        {canGoNext && (
          <VersatileButton
            // {...nextParams}
            content={"Formulaire suivant"}
            role="Button"
            cssClass="next-button"
            onClickCallback={nextForm}
            rightIcon={<RightArrowSVG />}
          />
        )}
      </div>
    </form>
  );
} // End function UsrForm
