import React from "react";
import ReactDOM from "react-dom/client";
//import { reportWebVitals } from "./reportWebVitals";

/* App component(s) */
import { App } from "./App";

/* App style */
import "./libs/icomoon/css/icomoon.css";

import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [],
  });
}
const root = ReactDOM.createRoot(document.getElementById("react-root"));
root.render(
  // If needed to make some bug tests make this React.StrictMode enable.
  // But it generate letter twice when enable.
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
