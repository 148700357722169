/**
 * @file Panneau de finalisation de la lettre
 * @author Craspouille
 */
import { useContext } from "react";

/* Button */
import { VersatileButton } from "../../VersatileButton.jsx";

/* Form */
import { SidePanelForm } from "./SidePanelForm.jsx";

/* Context */

/* SVG */
import { CrossSVG } from "../../svg/CrossSVG.jsx";

/* CSS */
// import "../scss/";
import { ModifyContext } from "../../../pages/LetterPage.jsx";
import "../../../scss/letters-motivation.scss";

/**
 *
 * @param {visibility} boolean
 * @param {updatedCoordsCallback} Object
 * @returns
 */
export const SidePanel = ({ visibility }) => {
  const { setPanelVisibility } = useContext(ModifyContext);

  return !!visibility ? (
    <>
      <div className="side-panel">
        <VersatileButton
          content={""}
          icon={<CrossSVG />}
          title={"Fermer la fenêtre"}
          cssClass={"close"}
          onClickCallback={() => {
            setPanelVisibility(false);
          }}
        />
        <SidePanelForm />
      </div>
    </>
  ) : (
    <div className="control-widget panelShow">
      <VersatileButton
        className="icon-edit"
        content={""}
        onClick={() => {
          setPanelVisibility(true);
        }}
        title={"Ouvrir l'outil d'édition"}
      />
    </div>
  );
}; // End function SidePanel
