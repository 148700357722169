import React, { useContext, useState } from "react";

import { AppContext } from "../../App";
import { VersatileButton } from "../VersatileButton";
import { ManageCoveringLetter } from "./ManageCoveringLetter";

/* Custom context */
// import { HomeContext } from '../pages/HomePage';

export const RecruitmentKit = () => {
  /*
  Embed all components used to render screen part "Mon kit de recrutement"
*/
  const { originalLetter } = useContext(AppContext);
  const [isExpanded, expand] = useState(true); // whole kit (un)folding flag
  const togglingIcons = {
    down: <span className="icon icon-arrow-down2"></span>,
    up: <span className="icon icon-arrow-up2"></span>,
  };

  return (
    <>
      <div className="recruitment-kit">
        <div className="recruitment-kit-content">
          <div className="title-and-folding-toggler-widget">
            <div>
              <h2>Mon kit de recrutement</h2>
            </div>
            <div
              className="accordion-toggler"
              title={isExpanded ? "Replier" : "Déplier"}
              onClick={toggleAccordion}
            >
              {isExpanded ? togglingIcons.up : togglingIcons.down}
            </div>
          </div>
          {isExpanded &&
            (originalLetter ? (
              <>
                <form
                  method="post"
                  encType="multipart/form-data"
                  className="form-recruitment"
                >
                  <ManageCoveringLetter />
                  {/*<ManageResume /> to be enabled in a later version */}
                  {/*<ManageRecommendationLetter /> to be enabled in a later version */}
                  {/* <ManageContactMessage /> */}
                </form>
                <VersatileButton
                  content={"Fermer"}
                  cssClass={"closing-widget"}
                  onClickCallback={toggleAccordion}
                />
              </>
            ) : (
              <VersatileButton
                content={"Générer une lettre de motivation"}
                cssClass={"new-letter-widget"}
                linkUrl={"/Questionnaire"}
              />
            ))}
        </div>
      </div>
    </>
  );

  function toggleAccordion(ev) {
    /* onClick handler */
    expand(!isExpanded); // invert kit expansion state
  } // end function toggleAccordion
}; // end of function component RecruitmentKit
