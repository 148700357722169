import React from "react";

export function EyeSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 255.81989 163.54141"
      role="img"
      aria-label="Icône œil"
      title="Icône œil"
    >
      <g>
        <path
          id="path817"
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277778,
          }}
          d="M 106.30841,162.40251 C 67.317105,156.02921 28.363215,130.42136 6.0442746,96.489955 c -8.10529,-12.32242 -8.0754,-16.97022 0.19009,-29.56722 C 28.706375,32.674355 67.884415,7.3289083 107.82335,1.2017763 c 12.94337,-1.98567496 35.42671,-1.44501896 47.28609,1.137086 38.30743,8.3405537 73.07492,32.1784007 94.66517,64.9058527 5.39884,8.18381 6.76801,12.51203 5.71871,18.07802 -0.35944,1.90666 -2.93286,6.93226 -5.71871,11.168 -21.57098,32.797565 -55.95859,56.416415 -94.40038,64.838125 -10.9722,2.40375 -37.39156,2.98186 -49.06582,1.07365 z m 38.20441,-17.69035 c 18.13304,-2.52207 36.96656,-10.08935 53.62208,-21.54525 10.2718,-7.06509 25.82585,-22.33242 33.6872,-33.066225 l 6.07058,-8.28871 -7.44166,-9.84462 C 218.57483,56.256285 201.09399,40.725406 187.44015,33.75427 l -3.93595,-2.009549 2.63941,5.765227 c 11.0079,24.044417 5.7465,52.034917 -13.34588,70.999752 -13.47627,13.38625 -26.02749,18.58194 -44.88829,18.58194 -18.8608,0 -31.412015,-5.19569 -44.888285,-18.58194 -12.47405,-12.390715 -19.04358,-28.017445 -19.09319,-45.416345 -0.0212,-7.45268 3.07507,-20.622855 6.36419,-27.070073 l 2.24748,-4.405423 -3.51509,1.811966 c -14.27299,7.357454 -31.62539,22.60821 -43.57129,38.29412 l -7.69249,10.10085 6.17623,8.2823 c 22.12812,29.673745 54.11982,49.818735 86.707335,54.599115 8.91928,1.30841 24.48452,1.31114 33.86849,0.006 z M 96.139385,68.256065 c 0.89498,-1.02667 1.96089,-4.49962 2.36869,-7.71766 1.882475,-14.855403 14.690765,-26.68234 28.896305,-26.68234 7.02606,0 9.60153,-9.329226 3.51422,-12.729699 -3.58813,-2.004387 -13.6999,-0.521815 -21.49681,3.151827 -13.988375,6.590858 -25.245675,23.965173 -25.245675,38.963702 0,5.71398 8.31511,9.19909 11.96327,5.01417 z"
        />
      </g>
    </svg>
  );
} // end function EyeSVG
