/**
 * @file Bouton de fil d'Ariane
 * @author Cody
 * @author Prof. Chaos
 */

import React, { createRef, useEffect, useState } from "react";

/**
 *
 * @param {Object} props
 * @returns {HTMLButtonElement} Stage Tab Button
 */
export function Breadcrumb({
  step,
  currentStepIndex,
  goToStepQuestion,
  questionnaireCompleted,
}) {
  const [myStatus, setMyStatus] = useState("waiting");
  const [onClick, setOnClick] = useState(null);
  const [hoverable, setHoverable] = useState(null);
  const [scrollMe, setScrollMe] = useState(false);

  const myBtn = createRef(null);

  const myFct = function goTo() {
    goToStepQuestion(step.index);
  };

  useEffect(() => {
    setScrollMe(false);
    setOnClick(null);
    setHoverable(null);

    if (questionnaireCompleted) {
      setOnClick(null);
      setHoverable(null);
      setMyStatus("done");
    } else {
      if (currentStepIndex === step.index) {
        setMyStatus("active");
        setScrollMe(true);
      } else if (currentStepIndex > step.index) {
        setMyStatus("done");
        setOnClick(true);
        setHoverable(true);
      } else {
        setMyStatus("waiting");
      }
    }
    // eslint-disable-next-line
  }, [currentStepIndex, questionnaireCompleted]);

  useEffect(() => {
    if (scrollMe) {
      myBtn.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [scrollMe, myBtn]);

  return (
    <button
      className="stage-tab-button"
      hoverable={hoverable && "true"}
      onClick={onClick && myFct}
      ref={myBtn}
      state={myStatus}
    >
      {step.label}
    </button>
  );
}
