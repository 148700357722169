/**
 * @file Date de la lettre rensigner par l'utilisateur
 * @author Craspouille
 */
import { useContext, useRef } from "react";

import { ModifyContext } from "../../../pages/LetterPage";
import { getOkayClass } from "../../../utils";

export function DateDetails() {
  const { userDetails, handleClickPanelVisibility } = useContext(ModifyContext);
  const formulaireDate = useRef(null); // Référence des containers

  return (
    <div
      className="user date-du-jour"
      onClick={handleClickPanelVisibility}
      ref={formulaireDate}
      data-steppers={3}
    >
      <p data-steppers={3} data-step={3}>
        <span className={getOkayClass(userDetails["date"])}>
          {userDetails["date"] === null || userDetails["date"] === ""
            ? "Date de la lettre" // navigator.language
            : "le " +
              new Date(userDetails["date"]).toLocaleString("fr-Fr", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
          ,
        </span>
      </p>
    </div>
  );
}
