/* React libs */
import React, { useContext } from "react";

/* Custom data */
import { assetsContext } from "../../contexts/assets-context.js";

/* Custom components */
import { PictureLink } from "../PictureLink";
import { LinkedInSVG } from "../svg/LinkedInSVG.jsx";

/* Custom style */
import "../../scss/employment-sites.scss";

/*
╔═════════════════════════════════════════════╗
║                  Component                  ║
╚═════════════════════════════════════════════╝*/

/* Render employment site icons links */

export function EmploymentSites(props) {
  const assetsCtx = useContext(assetsContext);
  let iconLinks = [
    {
      image: {
        src: require("../../assets/pics/icons/logo-pole-emploi.png"),
        // src: "https://scontent-cdg4-2.xx.fbcdn.net/v/t39.30808-6/416714416_1305992133502615_626369274698697749_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=5f2048&_nc_ohc=T8odkevZ8gIQ7kNvgEWXLKq&_nc_ht=scontent-cdg4-2.xx&oh=00_AYB_NmGZiRde_ewpDGtzPrGDYy5H4wUlxXyKbzJJBKUq_Q&oe=664D08EA",
        alt: "Icône France Travail",
      },
      label: "France Travail",
      gotourl: "https://www.francetravail.fr/accueil/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/Adecco.png"),
        alt: "Icône Adecco",
      },
      label: "Adecco",
      gotourl: "https://www.adecco.fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-manpower.png"),
        alt: "Icône Manpower",
      },
      label: "Manpower",
      gotourl: "https://www.manpower.fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-indeed.png"),
        alt: "Icône Indeed",
      },
      label: "Indeed",
      gotourl: "https://fr.indeed.com/?r=us",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-linkedin.png"),
        alt: "Icône LinkedIn",
      },
      label: "LinkedIn",
      gotourl: "https://www.linkedin.com/jobs",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-glassdoor.png"),
        alt: "Icône Glassdoor",
      },
      label: "Glassdoor",
      gotourl: "https://www.glassdoor.fr/member/home/index.htm",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-monster.png"),
        alt: "Icône Monster",
      },
      label: "Monster",
      gotourl: "https://www.monster.fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-jobijoba.png"),
        alt: "Icône Jobijoba",
      },
      label: "Jobijoba",
      gotourl: "https://www.jobijoba.com/fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-jobculture.png"),
        alt: "Icône Jobculture",
      },
      label: "Jobculture",
      gotourl: "https://www.jobculture.fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-hellowork.png"),
        alt: "Icône Hellowork",
      },
      label: "Hellowork",
      gotourl: "https://www.hellowork.com/fr-fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-paris-emploi.png"),
        alt: "Icône Paris emploi",
      },
      label: "Paris emploi",
      gotourl: "https://emploi.paris.fr/",
    },
    {
      image: {
        src: require("../../assets/pics/icons/icon-meteojob.png"),
        alt: "Icône Météojob",
      },
      label: "Météojob",
      gotourl: "https://www.meteojob.com/",
    },
  ];

  return (
    <div className="employment-sites">
      <div className="sites-icons">
        {iconLinks.map((item, key) => {
          return (
            <div className="site-icon-link" key={key}>
              <PictureLink
                gotourl={item.gotourl}
                image={item.image}
                key={key}
                label={item.label}
                svg={item.svg}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
} // end function Component EmploymentSites
