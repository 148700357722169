/*
  Build any-level dynamic HTML header (h1, h2, ...) in an optional 'box' (div) container.
  Enclosing basic HTML title in a container induces a 'block' display.
*/

import React from "react";

export const VersatileTitle = ({
  boxed = false,
  cssClass = {
    title: "app-title",
    box: "title-box",
  },
  titleLevel = 1,
  titleText = "PUT YOUR TITLE HERE",
}) => {
  const dynamicHtmlVersatileTitle = React.createElement(
    `h${titleLevel}`,
    { className: cssClass.title },
    titleText
  );

  if (boxed) {
    return <div className={cssClass.box}>{dynamicHtmlVersatileTitle}</div>;
  } else {
    return dynamicHtmlVersatileTitle;
  }
  // return  dynamicHtmlVersatileTitle ;
}; // end function component VersatileTitle
