import React, { useRef, useContext, useEffect } from "react";
import { WidgetInstance } from "friendly-challenge";
import { AppContext, localStorageKeys } from "../App";

import "../scss/friendly-captcha.scss";

export const FriendlyCaptcha = (props) => {
  const container = useRef();
  const widget = useRef();
  const { _, setCaptchaVerified } = useContext(AppContext);

  const doneCallback = (solution) => {
    console.log("Captcha finished with solution " + solution);
    fetch(process.env.REACT_APP_BACKEND_BASE_URL + "/verify-captcha", {
      method: "POST",
      body: JSON.stringify({ token: solution }),
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCaptchaVerified(true);
          localStorage.setItem(localStorageKeys.captchaVerified, true);
          return;
        } else {
          alert("La validation du CAPTCHA a échouée !");
          return;
        }
      });
  };

  const errorCallback = (err) => {
    alert("Il y a eu une erreur lors de la résolution du Captcha.");
  };

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "auto",
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      });
    }

    return () => {
      if (widget.current != undefined) widget.current.reset();
    };
  }, [container]);

  return (
    <div
      ref={container}
      className="frc-captcha"
      data-lang="fr"
      data-sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
      {...props}
    />
  );
};
