import React from "react";
import { Link } from "react-router-dom";

import "../scss/versatile-button.scss";

/*
  Generic versatile button with optional link and onClick callback.
*/

export const VersatileButton = ({
  id = null,
  cssClass = "versatile-button",
  onClickCallback,
  content = "BUTTON TEXT HERE",
  leftIcon = null,
  icon = null,
  /* Optional wrapping link */
  linkUrl = null,
  rightIcon = null,
  ...rest
}) => {
  let button = (
    <button
      className={cssClass}
      onClick={handleOnClickCallback}
      id={id}
      {...rest}
    >
      {leftIcon} <span className="content">{content}</span> {icon} {rightIcon}
    </button>
  );
  let url = { to: { pathname: linkUrl } };
  /* Wrap button with given target url link */
  return linkUrl ? <Link {...url}>{button}</Link> : button;

  function handleOnClickCallback(ev) {
    if (!linkUrl) {
      ev.preventDefault(); // mandatory
    }
    if (onClickCallback) {
      ev.preventDefault(); // mandatory
      onClickCallback(ev);
    }
  } // end function handleOnClickCallback;
}; // end of function component VersatileButton
