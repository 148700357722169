import React from "react";

import { InformationCard } from "./InformationCard";
import { VersatileTitle } from "./VersatileTitle";

import "../scss/information-panel.scss";

export const InformationPanel = ({
  cardContentArray = [],
  id,
  content,
  style,
  title = "Informations",
  titleLevel = 2,
  ...extras
}) => {
  /*
    Titled panel containing anything or generating information cards upon given data.
  */
  return (
    <>
      <div className="information-panel" id={id} style={style} {...extras}>
        <div className="panel-header">
          <VersatileTitle boxed={false} titleLevel={2} titleText={title} />
        </div>
        <div className="panel-content">
          {content ||
            cardContentArray.map((tuple, key) => {
              return (
                <InformationCard
                  article={tuple.article}
                  image={tuple.image}
                  key={key}
                  link={tuple.link}
                  subject={tuple.subject}
                  svg={tuple.svg}
                />
              );
            })}
        </div>
      </div>
    </>
  );
}; // end of function component InformationPanel
