/**
 * @file Pop-up d'indication
 * @author Craspouille
 */
import React from "react";

import "../scss/indication-magnet.scss";
/**
 *
 * @param {cssClass} classe css
 * @param { content } Text
 * @param {React.ReactProps} props
 */
export const IndicationMagnet = ({
  cssClass = "indication-magnet arrow-up",
  paragraphs = [],
  dom,
  setShowToolTip,
  ...props
}) => {
  return (
    <span
      ref={dom}
      className={cssClass}
      onClick={(e) => {
        setShowToolTip(false);
        e.stopPropagation();
      }}
    >
      {paragraphs.map((paragraph, idx) => (
        <p key={idx}>{paragraph}</p>
      ))}
    </span>
  );
};
