import React from "react";

export function HomeSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
      <g id="layer1">
        <path
          d="M8.17573 1.11943C7.79341 0.768963 7.20659 0.768963 6.82427 1.11943L0.65791 6.77203C0.24713 7.14859 0.513541 7.83347 1.0708 7.83347C1.40825 7.83347 1.68182 8.10704 1.68182 8.44449V13.5C1.68182 14.0523 2.12953 14.5 2.68182 14.5H5.04545C5.59774 14.5 6.04545 14.0523 6.04545 13.5V11.0556C6.04545 10.5034 6.49317 10.0556 7.04545 10.0556H7.95455C8.50683 10.0556 8.95455 10.5034 8.95455 11.0556V13.5C8.95455 14.0523 9.40226 14.5 9.95455 14.5H12.3182C12.8705 14.5 13.3182 14.0523 13.3182 13.5V8.44449C13.3182 8.10704 13.5917 7.83347 13.9292 7.83347C14.4865 7.83347 14.7529 7.14859 14.3421 6.77204L8.17573 1.11943Z"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277775,
          }}
          id="path380"
        />
      </g>
    </svg>
  );
} // end function HomeSVG
