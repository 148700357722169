/**
 * @file Création du fil d'Ariane
 * @author Cody
 * @author Prof. Chaos
 */

import React from "react";
import { Breadcrumb } from "./Breadcrumb";

import "../../scss/breadcrumbs.scss";

/**
 * Fil d'Ariane
 * @returns {HTMLDivElement} Questionnaire Header
 */
export function Breadcrumbs({
  steps = [],
  currentStepIndex,
  goToStepQuestion,
  questionnaireCompleted,
}) {
  return (
    <div className="questionnaire-header">
      <div className="breadcrumbs">
        {steps.map(function (step) {
          return (
            <Breadcrumb
              key={"s-" + step.index}
              step={step}
              currentStepIndex={currentStepIndex}
              goToStepQuestion={goToStepQuestion}
              questionnaireCompleted={questionnaireCompleted}
            />
          );
        })}
      </div>
    </div>
  );
}
