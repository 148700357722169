/*
  New paradigm top nav menu: no more links in this SPA.
  Every App nav menu item slides in/out its associated window.
*/

import React, { useContext, useEffect, useId, useState } from "react";

/* App components */
import { ModalBox } from "../components/ModalBox";
import { Picture } from "../components/Picture";

/* SVG components */
import { NavBarItem } from "../components/NavBarItem";
import { HomeSVG } from "../components/svg/HomeSVG";
import { LetterSVG } from "../components/svg/LetterSVG";
import { MobileMenuSVG } from "../components/svg/MobileMenuSVG";
import { VictoriaLogoSVG } from "../components/svg/VictoriaLogoSVG";
import { AccessibilitySVG } from "../components/svg/AccessibilitySVG";

/* App Context */
import { AppContext, localStorageKeys } from "../App";

/* Custom Style */
import "../scss/app-menu.scss";

/*
╔═════════════════════════════════════════════╗
║           Public Component: AppMenu         ║
╚═════════════════════════════════════════════╝*/

export function AppMenu() {
  const { appIsMobile } = useContext(AppContext);
  /* Knowing App menus' dimension prevents ModalBox from overlapping it. */
  const [appMenuDimensions, setAppMenuDimensions] = useState({
    width: 0, // top left corner X
    height: 0, // top left corner Y
  });
  // ModalBox's state initialization: empty and hidden. Re-rendered on state change.
  const [getModalBoxContent, setModalBoxContent] = useState({
    content: null,
  });
  // const [, forceComponentUpdate] = useState(false);

  useEffect((_) => {
    /* Current component's coordinates can only be requested here, once it has been rendered. */
    let appMenuBoundingRect = document
      .querySelector(".app-menu")
      .getBoundingClientRect();
    // console.debug("AppMenu DBG: width=%d height=%d", appMenuBoundingRect.width, appMenuBoundingRect.height)
    setAppMenuDimensions({
      width: appMenuBoundingRect?.width,
      height: appMenuBoundingRect?.top + appMenuBoundingRect?.height,
    });
  }, []);

  function showConfirmDialog(message) {
    return window.confirm(message);
  }

  const DATABASE = {
    appMenuItemsSpecs: [
      {
        url: "/Home/",
        label: "Accueil",
        image: {
          alt: "Icône accueil",
          src: require("../assets/pics/icons/icon-home.png"),
        },
        svg: <HomeSVG />,
        static: false,
        onClick: function (ev) {
          window.location = "/Home/";
        },
      },
      {
        url: "/Questionnaire/",
        label: "Créer une nouvelle lettre",
        image: {
          alt: "Icône nouvelle lettre",
          src: require("../assets/pics/icons/icon-letter.png"),
        },
        svg: <LetterSVG />,
        static: false,
        onClick: function (ev) {
          ev.preventDefault();
          const userConfirmed = showConfirmDialog(
            "Une lettre a déjà été générée, voulez-vous vraiment la supprimer pour en créer une nouvelle ?"
          );
          if (userConfirmed) {
            localStorage.removeItem(localStorageKeys.originalLetter);
            localStorage.removeItem(localStorageKeys.modifiedLetter);
            localStorage.removeItem(localStorageKeys.userDetails);
            localStorage.removeItem(localStorageKeys.companyDetails);
            localStorage.removeItem(localStorageKeys.blobSignature);
            localStorage.removeItem(localStorageKeys.answerLikesChoices);
            localStorage.removeItem(localStorageKeys.answerSkillsChoices);

            window.location = "/Questionnaire/";
            console.log(
              "EMPTY LOCAL STORAGE : ",
              localStorage.getItem(localStorageKeys.originalLetter)
            );
          } else {
            console.log(
              "NOT EMPTY LOCAL STORAGE : ",
              localStorage.getItem(localStorageKeys.originalLetter)
            );
          }
        },
      },
      {
        url: null,
        label: "Accessibilité",
        image: {
          alt: "Icône accessibilité",
          src: require("../assets/pics/icons/icon-accessibility.png"),
        },
        svg: <AccessibilitySVG />,
        static: true, // always visible on top menu whatever the App is Mobile or not
        onClick: function (ev) {
          //   forceComponentUpdate(true); // trigger useEffect Hook which sets menu's dimensions we need to use here.
          let accessibilityBtn = document.querySelector(".asw-menu-btn");
          if (!accessibilityBtn) {
            console.error("ASW button not found");
            return;
          }
          accessibilityBtn.click();
        },
      },
      {
        url: "/LegalNotices",
        label: "Mentions légales",
        // Only displayed on Mobile without image
        image: null,
        // svg: <AccessibilitySVG />,
        static: false,
        onlyMobile: true,
        onClick: function (ev) {
          window.location = "/LegalNotices";
        },
      },
      //   {
      //     url: '/Login/',
      //     label: 'Se connecter',
      //     image: {
      //       alt: "Icône connexion",
      //       src: require('../assets/pics/icons/icon-login.png')
      //     },
      //     svg: <LoginSVG />,
      //     static: false,
      //     onClick: function (ev) {
      //       forceComponentUpdate(true); // trigger useEffect Hook which sets menu's dimensions we need to use here.
      //       setModalBoxContent(
      //         {
      //           content: <Login/>,
      //           positionX : appMenuDimensions.width,
      //           positionY : appMenuDimensions.height
      //         }
      //       );
      //     }
      //   }
    ],
  };

  /* ====================================================
                Handle ModalBox closing
    ===================================================== */
  function clearModalBox() {
    // let getModalBoxContentContent = getModalBoxContent.content;
    setModalBoxContent({
      content: null,
    });
  }

  /* ====================================================
       Build App nav menu items upon window's innerWidth,
       i.e. Mobile or non Mobile.
     ====================================================*/
  let menuWidgetCategories = {
    static: [], // app menu items always displayed, whatever the window size is.
    regular: [], // app menu items used by Desktop and Mobile expandable menus.
  };
  /* ===========================================================
      Populate arrays with Desktop or Mobile App nav menu items.
     =========================================================== */
  DATABASE.appMenuItemsSpecs.forEach(function (item) {
    //    console.log( "static item : ", item );
    if (appIsMobile) {
      if (item.static) {
        // static nav items always visible outside Mobile nav menu.
        //      console.log( "FOUND STATIC ITEM  : ", item );
        menuWidgetCategories.static.push(item);
      } else {
        menuWidgetCategories.regular.push(item);
      }
    } // Desktop: all navbar items are considered 'regular'
    else {
      if (!item.onlyMobile) {
        menuWidgetCategories.regular.push(item);
      }
    }
  });

  /* =================================================
                     Render App menu
     =================================================
  */
  return (
    <nav className="app-menu">
      <div className="app-name" mobile={appIsMobile ? "true" : "false"}>
        <a href={"/"}>
          <Picture svg={<VictoriaLogoSVG />} />
        </a>
      </div>
      <div className="navbar-items">
        <AppMenuStaticItems items={menuWidgetCategories.static} />
        {appIsMobile ? (
          <AppMenuMobile items={menuWidgetCategories.regular} />
        ) : (
          <AppMenuDesktop items={menuWidgetCategories.regular} />
        )}
      </div>
      <ModalBox
        parentcallback={function (ev) {
          clearModalBox();
        }}
        {...getModalBoxContent}
      />
    </nav>
  );
} // end function component AppMenu

/*
╔═════════════════════════════════════════════════════════╗
║            Private Component: AppMenuDesktop            ║
╚═════════════════════════════════════════════════════════╝*/
function AppMenuDesktop({ menuCssClass = "desktop-menu", items, ...rest }) {
  return (
    <div className={menuCssClass} style={{ "--items-count": items.length }}>
      <AppMenuRegularItems items={items} menuCssClass={menuCssClass} />
    </div>
  );
} // end function AppMenuDesktop

/*
╔═════════════════════════════════════════════════════════╗
║              Private Component: MobileMenu              ║
╚═════════════════════════════════════════════════════════╝*/
function AppMenuMobile({ menuCssClass = "mobile-menu", items }) {
  const [isActive, activate] = useState(false);

  return (
    <>
      <AppMenuMobileToggler
        key={useId()}
        onclickcallback={handleMobileMenuToggler}
      />
      <div
        className={menuCssClass}
        is_active={"" + isActive}
        style={{ "--items-count": items.length }}
      >
        <AppMenuRegularItems items={items} key={useId()} />
      </div>
    </>
  );

  function handleMobileMenuToggler(ev) {
    activate(!isActive);
    menuCssClass += " active";
  }
} // End component AppMenuMobile

/*
╔═════════════════════════════════════════════════════════╗
║          Private Component: AppMenuMobileToggler        ║
╚═════════════════════════════════════════════════════════╝*/
function AppMenuMobileToggler({ onclickcallback }) {
  /* Widget inviting to expand / shrink Mobile app menu */
  return (
    <div
      className="static mobile-menu-toggler"
      key={useId()}
      onClick={onclickcallback}
    >
      <NavBarItem
        svg={<MobileMenuSVG />}
        label={"MENU"}
        onclickcallback={onclickcallback}
      />
    </div>
  );
} // end function component AppMenuMobileToggler

/*
╔═════════════════════════════════════════════════════════╗
║          Private Component: AppMenuRegularItems         ║
╚═════════════════════════════════════════════════════════╝*/
function AppMenuRegularItems({ items, ...rest }) {
  /*
    Regular menu items used in Mobile / Desktop App menu.
    NOTA: Desktop App menu gets ALL (static and regular ) items.
  */
  return (
    <>
      {items.map(function (item, key) {
        return (
          <NavBarItem
            cssClass={"regular"}
            key={key}
            label={item.label}
            svg={item.svg}
            url={item.url}
            onclickcallback={item.onClick}
          />
        );
      })}
    </>
  );
} // end function component AppMenuRegularItem

/*
╔═════════════════════════════════════════════════════════╗
║           Private Component: AppMenuStaticItems         ║
╚═════════════════════════════════════════════════════════╝*/
function AppMenuStaticItems({ items, ...rest }) {
  /*
    Static (always visible outside Mobile menu) app menu items.
    They are merged with regular nav items of Desktop menu.
    I did not find a way to generate unique IDs: the loop below cannot call useId().
  */
  return (
    <>
      {items.map(function (item, key) {
        return (
          <NavBarItem
            cssClass={"static"}
            key={key}
            label={item.label}
            onclickcallback={item.onClick}
            svg={item.svg}
          />
        );
      })}
    </>
  );
} // end function component AppMenuStaticItems
