import { useContext } from "react";
import { ModifyContext } from "../../../pages/LetterPage";

export function UserFormAddress({ cssClass = "usr-address" }) {
  const { userDetails } = useContext(ModifyContext);
  return (
    <div className={cssClass}>
      <p className="app-font-size-title2">Votre adresse&nbsp;:</p>
      <label htmlFor="address">
        <input
          type="text"
          name="address"
          placeholder="Adresse*"
          title="Adresse*"
          autoFocus
          required
          pattern={"[a-zA-Z]"}
          defaultValue={userDetails.address}
        />
      </label>
      <label htmlFor="zipcode">
        <input
          type="text"
          name="zipcode"
          placeholder="Code postal*"
          title="Code postal*"
          required
          pattern="[0-9]{5}"
          maxLength="5"
          inputMode="numeric"
          defaultValue={userDetails.zipcode}
        />
      </label>
      <label htmlFor="city">
        <input
          type="text"
          name="city"
          placeholder="Ville*"
          title="Ville*"
          required
          pattern={"[a-zA-z]{1,}"}
          defaultValue={userDetails.city}
        />
      </label>
    </div>
  );
}
