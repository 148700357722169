import React, { useContext } from "react";
import { AppContext } from "../App";
import "../scss/footer.scss";

export const Footer = () => {
  const { appIsMobile } = useContext(AppContext);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-links">
          <a href="https://etapweb.fr/" target="_blank" rel="noreferrer">
            Etap'Web
          </a>
          <span className="text-muted">&nbsp;x&nbsp;</span>
          <a href="https://codephenix.fr/" target="_blank" rel="noreferrer">
            CodePhenix
          </a>
          <span className="text-muted">&nbsp;x&nbsp;</span>
          <a
            href="https://studio.artefact3000.com/"
            target="_blank"
            rel="noreferrer"
          >
            Artefact
          </a>
        </p>
        {!appIsMobile && (
          <p className="legal-link">
            <a href="/LegalNotices">Mentions légales</a>
          </p>
        )}
      </div>
    </footer>
  );
};
