/**
 * @file Prévisualisation de la lettre de motivation
 * @author Craspouille
 *
 */
import React, {
  //createRef,
  useContext,
  useEffect,
  useRef,
} from "react";

// import { VersatileButton } from "../VersatileButton.jsx";
import { HandSignSVG } from "../../svg/HandSignSVG.jsx";
/* Context */
import { localStorageKeys } from "../../../App.jsx";
import { ModifyContext } from "../../../pages/LetterPage.jsx";
import { VersatileButton } from "../../VersatileButton.jsx";

export function UserCanvasSignature(props) {
  /* Context */
  const { canvas, setBlobSignature } = useContext(ModifyContext);

  /* UseRef */
  const coords = useRef({
    name: "signature",
    lastX: null,
    lastY: null,
  }).current;
  const pLock = useRef({ state: false }).current;
  const pointers = useRef(new Set()).current;

  function DrawDownSignature(e) {
    let ctx = canvas.current.getContext("2d");
    pointers.add(e.nativeEvent.pointerId);
    pLock.state = true;
    ctx.lineCap = "round";
    ctx.lineWidth = 3;
    ctx.strokeStyle = "black";
    coords.lastX = e.nativeEvent.offsetX;
    coords.lastY = e.nativeEvent.offsetY;
  } // End function DrawDownSignature

  function DrawMoveSignature(e) {
    let ctx = canvas.current.getContext("2d");
    // Blocage du pointer si l'état est à false et la taille inférieur à 1
    if (!pLock.state || pointers.size !== 1) return;
    // Début du trait de la signature
    ctx.beginPath();
    ctx.moveTo(coords.lastX, coords.lastY);
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.closePath();
    ctx.stroke();
    // Renseignement des coordonnées
    coords.lastX = e.nativeEvent.offsetX;
    coords.lastY = e.nativeEvent.offsetY;
  } // End function DrawMoveSignature

  function DrawUpSignature(e) {
    // Si l'état est à true
    if (pLock.state) {
      // Alors j'envoie l'URL du contenu du canvas
      const newBlobSignature = canvas.current.toDataURL();
      setBlobSignature(newBlobSignature);
      localStorage.setItem(localStorageKeys.blobSignature, newBlobSignature);
    }
    // Déletion du pointer
    pointers.delete(e.nativeEvent.pointerId);
    // Modification de l'état de pLock
    pLock.state = false;
  } // End function DrawUpSignature

  function cancelTouch(e) {
    // Blocage de la propagation des événements tactiles
    e.stopPropagation();
    e.preventDefault();
  } // End function cancelTouch

  // Vidage du canvas
  function emptyCanvas() {
    let ctx = canvas.current.getContext("2d");
    if (canvas.current) {
      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
      coords.lastX = 0;
      coords.lastY = 0;
      const newBlobSignature = canvas.current.toDataURL();
      setBlobSignature(newBlobSignature);
      localStorage.setItem(localStorageKeys.blobSignature, newBlobSignature);
    }
  } // End function emptyCanvas
  /* UseEffect */
  useEffect(
    (_) => {
      if (!canvas) return;
      canvas.current.ontouchstart = cancelTouch;
      canvas.current.ontouchmove = cancelTouch;
      canvas.current.ontouchend = cancelTouch;
    },
    [canvas]
  );

  return (
    <>
      <div className="signature-zone">
        {coords.lastX + coords.lastY > 0 ? null : <HandSignSVG />}
        <canvas
          className="signature"
          width="225"
          height="150"
          ref={canvas}
          onPointerDown={DrawDownSignature}
          onPointerMove={DrawMoveSignature}
          onPointerUp={DrawUpSignature}
          onPointerLeave={DrawUpSignature}
        ></canvas>
      </div>
      <div className="control-widget">
        <VersatileButton
          content="Recommencer ma signature"
          onClickCallback={emptyCanvas}
        />
      </div>
    </>
  );
} // end function UserCanvasSignature
