/**
 * @file Create the page which will get the questionnaire form
 * @author Prof. Chaos
 * @namespace QuestionnairePage
 */

import React from "react";
import { Questionnaire } from "../components/questionnaire/Questionnaire";
import "../scss/questionnaire.scss";

/**
 * Création de la page
 * @returns {HTMLElement} Page
 */
export function QuestionnairePage() {
  return (
    <div className="questionnaire">
      <Questionnaire />
    </div>
  );
}
