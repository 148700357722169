/**
 * @file Signature à main levée
 * @author Craspouille
 *
 */
import { useContext } from "react";

/* Components */
import { IndicationMagnet } from "../../IndicationMagnet.jsx";

/* SVG */
import { UserCanvasSignature } from "./UserCanvasSignature.jsx";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage.jsx";
import { AppContext } from "../../../App";

export function UserSignatureDateForm() {
  /**
   * @param {canvas} Référence
   * @param {Function} handleFormFieldUpdate
   */
  const { handleFormFieldUpdate, canvas } = useContext(ModifyContext);
  const { appIsMobile } = useContext(AppContext);
  const tooltipText = appIsMobile
    ? "Utilisez votre doigt pour signer"
    : "Utilisez votre souris pour signer";

  const showTooltip = canvas.current === null;

  return (
    <>
      <form>
        <div className="usr-date usrForm">
          <p className="app-font-size-title2">Quelle est la date&nbsp;:</p>
          <label htmlFor="date">
            <input
              type={"date"}
              name="date"
              pattern=""
              onInput={handleFormFieldUpdate}
              autoFocus
              placeholder="Renseigner la date du jour"
            />
          </label>
        </div>
        <div className="handwritten-signature">
          <p className="app-font-size-title2">Signer la lettre&nbsp;:</p>
          {showTooltip && (
            <IndicationMagnet
              paragraphs={[tooltipText]}
              cssClass="indication-magnet arrow-down"
            />
          )}
          <UserCanvasSignature />
        </div>
      </form>
    </>
  );
} // End function UserSignatureDateForm
