/**
 * @file Formulaire des expériences du candidat
 * @author Prof. Chaos
 */

import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext, localStorageKeys } from "../../App";
import { QuestionnaireFormControlWidget } from "./QuestionnaireFormControlWidget";
import axios from "axios";

function cleanChoice(choice) {
  // Remove "- " and newlines, and trim whitespace
  let cleaned = choice.replaceAll("- ", "").replaceAll("\n", "").trim();

  // Remove the first character if it is not a letter
  if (
    cleaned.length > 0 &&
    /[!@#$%^&*(),.?":{}|<>[\]\\/'`~\-+=_;]/.test(cleaned[0])
  ) {
    cleaned = cleaned.slice(1).trim();
  }

  // Remove the last character if it is not a letter, number, or "."
  if (
    cleaned.length > 0 &&
    /[!@#$%^&*(),?":{}|<>[\]\\/'`~\-+=_;]/.test(cleaned[cleaned.length - 1])
  ) {
    cleaned = cleaned.slice(0, -1).trim();
  }

  // Always put an ending dot
  if (cleaned.slice(-1) !== ".") {
    cleaned = cleaned + ".";
  }

  return cleaned;
}

export const QuestionnaireForm = function ({
  questions = [],
  nextQuestion,
  path, // tableau où s'empilent les réponses de l'utilisateur au fil des questions
  setPath,
  nextQuestionId,
}) {
  const navigate = useNavigate();
  const {
    valueLikesRef,
    valueSkillsRef,
    setAnswers,
    setLoadingQuestionLikes,
    setLoadingQuestionSkills,
    captchaVerified,
  } = useContext(AppContext);

  const currentQuestionIndex = useRef(null);
  const formSubmit = function (e) {
    e.preventDefault();
  };

  useEffect(() => {
    if (!captchaVerified) {
      alert("Veuillez cocher la case 'Je ne suis pas un robot'");
      navigate("/Introduction");
      return;
    }
  }, [captchaVerified, navigate]);

  useEffect(
    (_) => {
      currentQuestionIndex.current =
        (path && path.length && path.slice(-1)[0].index) || null;
    },
    [path]
  );

  const goNextQuestion = function () {
    // If not last question

    if (nextQuestion !== -1) {
      const currentQuestion = path.slice(-1)[0];
      if (currentQuestion.id === "aqtp") {
        // The user has just answered the question about the job title,
        // we fetch the last questions
        const job_title = currentQuestion.selectedChoices.values().next()
          .value.userAnswer;
        // console.log("Fetching last Questions", job_title);
        const lastQuestions = [
          {
            postUrl: "/choices-question-likes",
            localStorageKey: localStorageKeys.answerLikesChoices,
            loadingSetter: setLoadingQuestionLikes,
          },
          {
            postUrl: "/choices-question-skills",
            localStorageKey: localStorageKeys.answerSkillsChoices,
            loadingSetter: setLoadingQuestionSkills,
          },
        ];
        let question = "";
        for (question of lastQuestions) {
          const urlApi =
            process.env.REACT_APP_BACKEND_BASE_URL + question.postUrl;
          let localStorageKey = question.localStorageKey;
          let loadingSetter = question.loadingSetter;
          axios
            .post(urlApi, job_title, {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              const parsedChoices = res.data.proposed_choices
                .split("\n")
                .map(cleanChoice)
                .filter((choice) => choice !== "");

              localStorage.setItem(
                localStorageKey,
                JSON.stringify(parsedChoices)
              );
              loadingSetter(false);
            })
            .catch((err) => console.log(err));
        }
      }
      setPath([...path, nextQuestion]);
      // To avoid the user quitting the questionnaire by clicking
      // the back button of the browser
      navigate(".");
      navigate(".");
    } else {
      // No more questions
      setAnswers(
        path.map((question) => ({
          title: question.title,
          answers: Array.from(question.selectedChoices).map((choice) => ({
            givenAnswer:
              (choice.isUserInput && choice.userAnswer) || choice.label,
            isUserInput: choice.isUserInput,
          })),
        }))
      );

      navigate("/Letter");
    }
  };

  const goPrevQuestion = function () {
    setPath([...(path.pop() && path)]);
  };

  let myData;
  let tableData = [];
  path.forEach((element) => {
    myData = Array.from(element.component.props.selectedChoices);
    myData.forEach((e) => {
      // console.log(e.userAnswer);
      if (e.label !== "+Autre" && e.label !== "Donner ma réponse") {
        tableData.push(e.label);
      }
      if (e.to === "cetr") {
        tableData.push(valueLikesRef);
      }
      if (e.to === -1) {
        tableData.push(valueSkillsRef);
      } else {
        tableData.push(e.userAnswer);
      }
    });
  });

  return (
    <form
      name="experience-form"
      className="questionnaire-body"
      onSubmit={formSubmit}
    >
      {(currentQuestionIndex && (
        <div id="question-number">
          Question {currentQuestionIndex.current + 1} /{" "}
          {Object.entries(questions).length}
        </div>
      )) || <div id="question-number">Pas de questions</div>}
      {path && path.length && path.slice(-1)[0].component}
      {nextQuestionId && (
        <QuestionnaireFormControlWidget
          path={path}
          canGoPrev={path?.length > 1}
          canGoNext={!!nextQuestion}
          goNextQuestion={goNextQuestion}
          goPrevQuestion={goPrevQuestion}
        />
      )}
    </form>
  );
};

// QuestionnaireForm.displayName = "Générer un formulaire";
