import React from "react";

export function LetterSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.5155864 5.6444445"
      role="img"
      aria-label="Icône menu pour création nouvelle lettre"
      title="Icône menu pour création nouvelle lettre"
    >
      <g id="layer1" transform="translate(-36.837926,-121.93942)">
        <g
          id="g396"
          transform="matrix(0.35277777,0,0,-0.35277777,36.837926,127.58386)"
        >
          <path
            d="M 8.000055,16 H 1.600011 C 0.720005,16 0.008,15.28 0.008,14.4 L 0,1.599999 C 0,0.719999 0.712005,0 1.592011,0 h 9.608066 c 0.880006,0 1.600011,0.719999 1.600011,1.599999 V 11.2 Z M 7.200049,11.2 v 3.6 l 3.600026,-3.6 z"
            style={{
              fill: "#000000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
            id="path398"
          />
        </g>
        <path
          id="path402"
          style={{
            fill: "#ffffff",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277778,
          }}
          d="m 38.837018,125.76118 h -0.587963 v -0.58797 h 0.587963 v -0.58796 h 0.587963 v 0.58796 h 0.587963 v 0.58797 h -0.587963 v 0.58796 h -0.587963 z"
        />
      </g>
    </svg>
  );
} // end function LetterSVG
