/**
 * @file Questionnaire identité de l'employeur
 * @author Craspouille
 */
import { useContext, useRef } from "react";

/* Component Form */
import { CompanyFormAddress } from "./CompanyFormAddress.jsx";
import { CompanyFormEnterprise } from "./CompanyFormEnterprise.jsx";
import { CompanyFormIdentity } from "./CompanyFormIdentity.jsx";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage.jsx";
/* Button */
import { VersatileButton } from "../../VersatileButton.jsx";
/* SVG */
import { RightArrowSVG } from "../../svg/RightArrowSVG.jsx";

export function CompanyForm() {
  /* Context */
  const {
    step,
    setStep,
    handleFormFieldUpdate,
    canGoNext,
    setCanGoNext,
    setNextStep,
    prevForm, // Passage au formulaire précédent
    nextForm, // Passage au formulaire suivant
  } = useContext(ModifyContext);

  const formulaire = useRef(null);

  function checkValidities(e) {
    console.log(
      "checkValidities",
      formulaire.current,
      formulaire.current.checkValidity()
    );
    setCanGoNext(formulaire.current.checkValidity());
  }

  /* Liste des formulaires */
  const details = [
    <CompanyFormEnterprise setstep={setStep} />,
    // <CompanyFormIdentity setstep={setStep} />,
    <CompanyFormAddress setstep={setStep} />,
  ];

  /* Paramètres des boutons de navigations */
  let prevParams = {
    ...(step === 0 && { disabled: true }),
  };

  let nextParams = {
    ...(step >= details.length - 1 && { disabled: true }),
  };

  /* Fonctions des boutons de navigations */
  function handleNext() {
    if (step < details.length) {
      setStep(step + 1);
      setNextStep(false);
    }
  }

  function prev() {
    if (step > 0) setStep(step - 1);
  }

  /**
   * Inhiber le comportement par défaut du questionnaire
   * @param {SubmitEvent} e
   */
  function onSubmit(e) {
    e.preventDefault();
  }

  return (
    <form
      method="POST"
      encType="multipart/form-data"
      id="EmplFrom"
      ref={formulaire}
      onSubmit={onSubmit}
      onInput={handleFormFieldUpdate}
      onChange={checkValidities}
    >
      {details[step]}
      <div className="form-widget-steps">
        {step === 0 ? (
          <VersatileButton
            content={"Formulaire précédent"}
            cssClass="previous-button"
            leftIcon={<RightArrowSVG />}
            onClickCallback={prevForm}
          />
        ) : (
          <VersatileButton
            {...prevParams}
            content={"Précédent"}
            cssClass="previous-button"
            leftIcon={<RightArrowSVG />}
            onClickCallback={prev}
          />
        )}
        {canGoNext && (
          <VersatileButton
            content={"Formulaire suivant"}
            role="Button"
            cssClass="next-button"
            onClickCallback={nextForm}
            rightIcon={<RightArrowSVG />}
          />
        )}
        {!canGoNext && (
          <VersatileButton
            {...nextParams}
            content={"Suivant"}
            role="Button"
            cssClass="next-button"
            onClickCallback={handleNext}
            rightIcon={<RightArrowSVG />}
          />
        )}
        {/* <VersatileButton
          {...nextParams}
          content={"Suivant"}
          cssClass="next-button"
          rightIcon={<RightArrowSVG />}
          onClickCallback={Next}
        /> */}
      </div>
    </form>
  );
}
