/**
 * @file Composant Question
 * @author Prof. Chaos
 */

import React, { useEffect, useRef, useState } from "react";

/* Custom components */
import { IndicationMagnet } from "../IndicationMagnet";
import { HelpButtonSVG } from "../svg/HelpButtonSVG";
import { QuestionnaireFormInput } from "./QuestionnaireFormInput";

export const Question = function ({
  setNextQuestionId,
  myQuestion,
  selectedChoices,
}) {
  const [mySelectedChoices, setMySelectedChoices] = useState(selectedChoices);
  const [userInputMode, setUserInputMode] = useState(
    myQuestion.isDirectInput && myQuestion.choices[0]
  );
  const [isValid, setValid] = useState(myQuestion.isValidByDefault);
  const [globalHelpParagraphs, setGlobalHelpParagraphs] = useState(
    myQuestion.helpParagraphs || null
  );
  const [showToolTip, setShowToolTip] = useState(false);

  const myTootTip = useRef(null);

  const selectChoice = function (choice, state, update) {
    if (update) {
      setMySelectedChoices(new Set(selectedChoices));
      return;
    }
    setUserInputMode(state && choice.isUserInput && choice);
    if (state) {
      if (!myQuestion.isMulti) {
        selectedChoices.clear();
      }
      selectedChoices.add(choice);
      setMySelectedChoices(new Set(selectedChoices));
    } else {
      selectedChoices.delete(choice);
      setMySelectedChoices(new Set(selectedChoices));
    }
  };

  useEffect(
    (_) => {
      setValid(
        (mySelectedChoices.size &&
          Array.from(mySelectedChoices).every((choice) => choice.isValid)) ||
          false
      );
    },
    [mySelectedChoices]
  );

  useEffect(
    (_) => {
      myQuestion.isValid = myQuestion.isValidByDefault || isValid;

      if (myQuestion.isValidByDefault) {
        setNextQuestionId(myQuestion.to);
        return;
      }
      if (!isValid) {
        setNextQuestionId(-2);
        return;
      }

      if (mySelectedChoices.size) {
        let to = new Set();
        let isQuestionInvalid = Array.from(mySelectedChoices).some(function (
          selectedChoice
        ) {
          to.add(selectedChoice.to);
          return selectedChoice.isUserInput && !selectedChoice.isValidAnswer;
        });
        console.assert(
          to.size <= 1,
          "@@@ <Question.jsx> - Multi (Défaillance causée par une erreur du programmeur)"
        );
        if (isQuestionInvalid || to.size !== 1) {
          setNextQuestionId(-3);
        } else {
          let toto = to.values().next().value;
          setNextQuestionId(toto);
        }
      } else {
        setNextQuestionId(-2);
      }
    },
    [isValid, mySelectedChoices]
  );

  return (
    <fieldset
      className="choices"
      onClick={(e) => {
        setShowToolTip(false);
        e.stopPropagation();
      }}
    >
      <legend className="question-title">{myQuestion.title}&nbsp;?</legend>
      {globalHelpParagraphs && (
        <div
          className="help-widget"
          onClick={(e) => {
            setShowToolTip(!showToolTip);
            e.stopPropagation();
          }}
        >
          <HelpButtonSVG />
          {showToolTip && (
            <IndicationMagnet
              dom={myTootTip}
              paragraphs={globalHelpParagraphs}
              setShowToolTip={setShowToolTip}
            />
          )}
        </div>
      )}
      <div className="choice-container">
        {myQuestion.choices.map((choice) => {
          return (
            <QuestionnaireFormInput
              key={"chk:" + choice.id}
              choice={choice}
              selectChoice={selectChoice}
              userInputMode={userInputMode}
              isUserChecked={selectedChoices.has(choice)}
              myQuestion={myQuestion}
              setGlobalHelpParagraphs={setGlobalHelpParagraphs}
            />
          );
        })}
      </div>
    </fieldset>
  );
};

// Question.displayName = "Question";
