/**
 * @file Navigation entre les questions
 * @author Prof. Chaos
 */

import { React } from "react";

import { RightArrowSVG } from "../svg/RightArrowSVG.jsx";
import { VersatileButton } from "../VersatileButton";

import "../../scss/button.scss";

export function QuestionnaireFormControlWidget({
  goNextQuestion,
  goPrevQuestion,
  canGoPrev,
  canGoNext,
}) {
  let nextParams = {
    disabled: (!canGoNext && true) || null,
    onClick: (e) => goNextQuestion(e),
    content: <span>Suivant</span>,
    rightIcon: <RightArrowSVG />,
    className: "button next-button",
  };

  let prevParams = {
    disabled: (!canGoPrev && true) || null,
    onClick: (e) => goPrevQuestion(e),
    content: <span>Précédent</span>,
    leftIcon: <RightArrowSVG style={{ transform: "rotate(180deg)" }} />,
    className: "button previous-button",
  };

  /* Render current question's prev / next navigation control widgets  */
  return (
    <>
      <div className="control-widgets">
        <div className="control-widgets-content">
          <VersatileButton {...prevParams} />
          <VersatileButton {...nextParams} />
        </div>
      </div>
    </>
  );
}
