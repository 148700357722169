import React from "react";

/* SVG components */
import { PictureBox } from "../components/PictureBox.jsx";
import { RightArrowSVG } from "../components/svg/RightArrowSVG.jsx";

import "../scss/information-card.scss";

/*const data = {
  label: 'Suivant',
  image: {
    alt: "Icône suivant",
    src: require('../assets/pics/icons/icon_suivant.png')
  },
  svg: <RightArrowSVG />
};*/

/*
╔═════════════════════════════════════════════╗
║                  Component                  ║
╚═════════════════════════════════════════════╝*/

export function InformationCard({
  article,
  image,
  link,
  style,
  subject,
  svg,
  ...extras
}) {
  return (
    <>
      <div className="information-card" style={style}>
        {(image || svg) && <PictureBox image={image} svg={svg} {...extras} />}
        <div className="card-block">
          <h3 className="subject">{subject}</h3>
          <div className="description">{article}</div>
          <div className="read-more">
            <a href={link.url}>
              {link.text || "Voir plus..."}
              <div className="widget">
                <RightArrowSVG />
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
} // end function component InformationCard
