/**
 * @file Composant Choix
 * @author Prof. Chaos
 */

import React, { useContext, useRef } from "react";
import { AppContext, localStorageKeys } from "../../App";

export const QuestionnaireFormMutexInput = ({
  choice,
  selectChoice,
  isUserChecked,
}) => {
  const {
    setValueLikesRef,
    setValueSkillsRef,
    loadingQuestionLikes,
    loadingQuestionSkills,
  } = useContext(AppContext);

  const LikesRef = useRef(null);
  const tenRef = useRef(null);

  const handleClickLikes = (event) => {
    const spanValue = event.target.innerText;
    setValueLikesRef(spanValue);
    // You can then do whatever you want with the span value, like updating state or performing other actions
  };

  const handleClickSkills = (event) => {
    const spanValue = event.target.innerText;
    setValueSkillsRef(spanValue);
    // You can then do whatever you want with the span value, like updating state or performing other actions
  };

  let choiceLikes = null;
  let choiceSkills = null;
  const choicesLikes = JSON.parse(
    localStorage.getItem(localStorageKeys.answerLikesChoices)
  );
  const choicesSkills = JSON.parse(
    localStorage.getItem(localStorageKeys.answerSkillsChoices)
  );

  //   Remove first and last parenthesis if present
  const clearParenthesis = (choice) => {
    if (choice[0] === "(") {
      choice = choice.slice(1);
    }
    if (choice[choice.length - 1] === ")") {
      choice = choice.slice(0, -1);
    }
    return choice;
  };

  if (choicesLikes && choice.to === "cetr") {
    if (choice.index === 0) {
      choiceLikes = choice.label.replace(choice.label, choicesLikes[0]);
    } else if (choice.index === 1) {
      choiceLikes = choice.label.replace(choice.label, choicesLikes[1]);
    } else if (choice.index === 2) {
      choiceLikes = choice.label.replace(choice.label, choicesLikes[2]);
    } else if (choice.index === 3) {
      choiceLikes = "Autre";
    }
  }
  if (choicesSkills && choice.to === -1) {
    if (choice.index === 0) {
      choiceSkills = choice.label.replace(choice.label, choicesSkills[0]);
    } else if (choice.index === 1) {
      choiceSkills = choice.label.replace(choice.label, choicesSkills[1]);
    } else if (choice.index === 2) {
      choiceSkills = choice.label.replace(choice.label, choicesSkills[2]);
    } else if (choice.index === 3) {
      choiceSkills = "Autre";
    }
  }
  let onChangeHandler = (e) => selectChoice(choice, !isUserChecked);
  if (
    (choice.to === "cetr" && loadingQuestionLikes) ||
    (choice.to === -1 && loadingQuestionSkills)
  ) {
    // Disable until choices are loaded
    onChangeHandler = () => {};
  }
  //   If last question, add a specific class to make
  //   the choices smaller as they are longer
  const labelClassName = choice.to === -1 ? "question-ten" : "";
  return (
    <>
      <div className="choice">
        <input
          readOnly={true}
          onChange={onChangeHandler}
          className="listed-answer"
          type="checkbox"
          id={choice.id}
          name={`question-${choice.qIndex}`}
          value={choice.index ?? ""}
          userchecked={(isUserChecked && "true") || null}
          data-index={choice.index}
        />
        <label htmlFor={choice.id} className={labelClassName}>
          {choice.to !== "cetr" && choice.to !== -1 && (
            <span key={choice.id}>{choice.label}</span>
          )}

          {choice.to === "cetr" &&
            (loadingQuestionLikes ? (
              <span className="loader"></span>
            ) : (
              <span key={choice.id} ref={LikesRef} onClick={handleClickLikes}>
                {choiceLikes}
              </span>
            ))}
          {choice.to === -1 &&
            (loadingQuestionSkills ? (
              <span className="loader"></span>
            ) : (
              <span key={choice.id} ref={tenRef} onClick={handleClickSkills}>
                {choiceSkills}
              </span>
            ))}
        </label>
      </div>
    </>
  );
};

// Choice.displayName = "Proposition unitaire";
