import React, { createContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";

/* Application */
import { AppMenu } from "./components/AppMenu";
import { VictoriaRoutes } from "./VictoriaRoutes.jsx";

/* Custom Style */
import "./scss/common.scss";
import "./scss/accessibility-panel.scss";

export const AppContext = createContext({});
const SCREEN = {
  MOBILE: {
    MAXWIDTH: 768,
  },
};
export const localStorageKeys = {
  originalLetter: "VICTORIA_ORIGINAL_LETTER",
  modifiedLetter: "VICTORIA_MODIFIED_LETTER",
  userDetails: "VICTORIA_USER_DETAILS",
  companyDetails: "VICTORIA_COMPANY_DETAILS",
  blobSignature: "VICTORIA_SIGNATURE_BLOB",
  answerLikesChoices: "VICTORIA_ANSWER_LIKES_CHOICES",
  answerSkillsChoices: "VICTORIA_ANSWER_SKILLS_CHOICES",
  captchaVerified: "VICTORIA_CAPTCHA_VERIFIED",
};

const getFromLocalStorage = (key) => {
  return localStorage.getItem(key)
    ? localStorage.getItem(key) // maybe JSON.parse it ??
    : null;
};
/*
╔═════════════════════════════════════════════╗
║                                             ║
║             Application component           ║
║                                             ║
╚═════════════════════════════════════════════╝*/

const AppWrapper = ({ children }) => {
  // Change app class depending on location path
  const location = useLocation();
  const appClass = location.pathname === "/Letter" ? "app-letter" : "app";

  return <div className={appClass}>{children}</div>;
};

export function App() {
  const [answers, setAnswers] = useState([]);
  const [appIsMobile, setAppIsMobile] = useState(
    window.innerWidth < SCREEN.MOBILE.MAXWIDTH
  );
  const [appWidth, setAppWidth] = useState(window.innerWidth);
  const [appHeight, setAppHeight] = useState(window.innerHeight);

  const [profil, setProfil] = useState();
  const [valueLikesRef, setValueLikesRef] = useState();
  const [valueSkillsRef, setValueSkillsRef] = useState();
  const [loadingQuestionLikes, setLoadingQuestionLikes] = useState(true);
  const [loadingQuestionSkills, setLoadingQuestionSkills] = useState(true);

  const [originalLetter, setOriginalLetter] = useState(
    getFromLocalStorage(localStorageKeys.originalLetter)
  );
  const [modifiedLetter, setModifiedLetter] = useState(
    getFromLocalStorage(localStorageKeys.modifiedLetter)
  );

  const [captchaVerified, setCaptchaVerified] = useState(
    getFromLocalStorage(localStorageKeys.captchaVerified) ||
      process.env.NODE_ENV === "development"
  );

  const popupBoxInUse = useRef(false); // only one popup box can be opened
  let appMenuCoordinates = { positionX: null, positionY: null };

  useEffect(() => {
    console.log("Matomo script loaded", process.env.REACT_APP_MATOMO_URL);
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = process.env.REACT_APP_MATOMO_URL;
    s.parentNode.insertBefore(g, s);
  }, []);

  useEffect((_) => {
    window.onresize = function () {
      setAppIsMobile(window.innerWidth < SCREEN.MOBILE.MAXWIDTH);
      setAppWidth(window.innerWidth);
      setAppHeight(window.innerHeight);
    };
  }, []);

  return (
    <AppContext.Provider
      value={{
        appIsMobile,
        appWidth,
        appHeight,
        appMenuCoordinates,
        popupBoxInUse,
        answers,
        setAnswers,

        captchaVerified,
        setCaptchaVerified,

        //LocalStorage - Letter
        originalLetter,
        setOriginalLetter,
        modifiedLetter,
        setModifiedLetter,

        //Questionnaire Q9/Q10/Profil + LocalStorage - Q9/Q10
        valueLikesRef,
        setValueLikesRef,
        valueSkillsRef,
        setValueSkillsRef,
        profil,
        setProfil,
        loadingQuestionLikes,
        setLoadingQuestionLikes,
        loadingQuestionSkills,
        setLoadingQuestionSkills,
      }}
    >
      <BrowserRouter>
        <AppWrapper>
          <AppMenu />
          <VictoriaRoutes />
        </AppWrapper>
      </BrowserRouter>
    </AppContext.Provider>
  );
} // end function component App
