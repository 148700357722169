import { useContext } from "react";
import { ModifyContext } from "../../../pages/LetterPage";

export function CompanyFormAddress() {
  const { companyDetails } = useContext(ModifyContext);
  return (
    <div className="empl-address">
      <p className="app-font-size-title2">Adresse de l'employeur&nbsp;:</p>
      <label htmlFor="address">
        <input
          autoFocus
          type="text"
          name="address"
          placeholder="Adresse*"
          // pattern={"[0-9]\\s[a-zA-Z]"}
          defaultValue={companyDetails.address}
          required
        />
      </label>
      <label htmlFor="zipcode">
        <input
          type="text"
          name="zipcode"
          placeholder="Code postal*"
          title="Code postal*"
          required
          pattern="[0-9]{5}"
          maxLength="5"
          inputMode="numeric"
          defaultValue={companyDetails.zipcode}
        />
      </label>
      <label htmlFor="city">
        <input
          type="text"
          name="city"
          placeholder="Ville*"
          title="Ville*"
          required
          pattern={"[a-zA-z]{1,}"}
          defaultValue={companyDetails.city}
        />
      </label>
    </div>
  );
}
