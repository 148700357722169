import React from "react";

export function AccessibilitySVG() {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.9681387 5.9130999"
      role="img"
      aria-label="Icône menu accessibiité"
      title="Icône menu accessibiité"
    >
      <g>
        <path
          id="path1259"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277778,
          }}
          d="m 1.3918354,5.6442965 c -0.0294,-0.01078 -0.0596,-0.02016 -0.0884,-0.03243 -0.13531,-0.05729 -0.2066,-0.187227 -0.18045,-0.331462 0.11621,-0.641586 0.23647,-1.282532 0.34799,-1.924865 0.0418,-0.240888 0.0654,-0.485084 0.094,-0.728212 0.006,-0.05398 5.4e-4,-0.109776 -0.003,-0.164611 -0.005,-0.0782 -0.0439,-0.136553 -0.11707,-0.163116 -0.11973,-0.04353 -0.24117,-0.08236 -0.36272,-0.120872 -0.23146002,-0.0734 -0.46420002,-0.142634 -0.69481002,-0.218804 -0.0768,-0.02539 -0.15228,-0.06006 -0.22143,-0.101988 -0.18557,-0.112443 -0.21919,-0.338183 -0.0822,-0.515595 0.0744,-0.09633 0.16818,-0.125992 0.28706,-0.09697 0.36133,0.08812 0.72117002,0.184026 1.08538002,0.25881 0.40776,0.08364 0.82031,0.115431 1.23745,0.06582 0.50156,-0.05964 0.99073,-0.174958 1.47596,-0.310018 0.23423,-0.06518 0.40615,0.05995 0.42056,0.304684 0.009,0.147435 -0.0619,0.248249 -0.1859,0.306711 -0.13745,0.06476 -0.2813,0.11671 -0.42451,0.168451 -0.2449,0.08844 -0.49205,0.170372 -0.73707,0.258598 -0.20606,0.07425 -0.24779,0.131752 -0.22047,0.349597 0.0434,0.346397 0.0925,0.692474 0.1509,1.03663 0.0701,0.413927 0.15238,0.825826 0.22943,1.23858 0.0205,0.109775 0.0415,0.219445 0.0623,0.32922 0.0364,0.192455 -0.0505,0.330182 -0.23989,0.380962 -0.008,0.0021 -0.0152,0.0071 -0.0226,0.01088 h -0.15975 c -0.14161,-0.03627 -0.21887,-0.135273 -0.26092,-0.268199 -0.009,-0.02699 -0.0153,-0.05473 -0.0208,-0.08257 -0.0447,-0.222325 -0.083,-0.446251 -0.13488,-0.66687 -0.0685,-0.291348 -0.14641,-0.580457 -0.22325,-0.869779 -0.014,-0.05249 -0.0387,-0.102948 -0.0639,-0.151489 -0.0321,-0.06188 -0.0606,-0.06049 -0.0956,0.0013 -0.0122,0.02155 -0.0248,0.04374 -0.0316,0.06721 -0.0609,0.209203 -0.12998,0.416699 -0.17843,0.628784 -0.0757,0.331461 -0.13648,0.666336 -0.20457,0.999611 -0.033,0.161517 -0.1034,0.294016 -0.27734,0.341917 h -0.15976 z"
        />
        <path
          d="M 2.3014554,1.5419408e-6 C 2.6449654,7.4854194e-4 2.9155954,0.27502854 2.9147354,0.62163854 c -8.5e-4,0.342876 -0.27606,0.61438196 -0.62203,0.61363496 -0.34234,-7.46e-4 -0.61414,-0.27641296 -0.61339,-0.62227596 8.6e-4,-0.342983 0.27554,-0.61374299806 0.62214,-0.6129959980592 z"
          id="path1263"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277778,
          }}
        />
      </g>
    </svg>
  );
} // end function AccessibilitySVG
