import { createContext } from "react";

const ASSETS = {
  path: {
    assets: "assets/",
    icons: "assets/pics/icons/",
    logos: "assets/pics/logos/",
    people: "assets/pics/photos/people/",
    photos: "assets/pics/photos/",
    pics: "assets/pics/",
    pictos: "assets/pics/pictos/",
  },
};

export const assetsContext = createContext(ASSETS);
