import { useContext } from "react";
import { ModifyContext } from "../../../pages/LetterPage";

export function UserFormEmailPhone({ cssClass = "usr-phone", state }) {
  const { userDetails } = useContext(ModifyContext);
  return (
    <div className={cssClass}>
      <div className="usr-identity">
        <p className="app-font-size-title2">Votre adresse email&nbsp;:</p>
        <label htmlFor="email">
          <input
            type="email"
            name="email"
            placeholder={"Email*"}
            pattern={"[^@s]+@[^@s]+.[^@s]+"}
            defaultValue={userDetails.email}
            required
          />
        </label>
        <p className="app-font-size-title2">Votre numéro de téléphone&nbsp;:</p>
        <label htmlFor="phone">
          <input
            type="tel"
            name="phone"
            placeholder={"Téléphone*"}
            autoFocus
            maxLength={10}
            defaultValue={userDetails.phone}
            required
          />
        </label>
      </div>
    </div>
  );
}
