/**
 * @file Questionnaire identité de l'utilisateur
 * @author Craspouille
 */
import React, { useContext, useEffect, useRef } from "react";

/* Context */
import { ModifyContext } from "../../../pages/LetterPage.jsx";

/* Formulaires */
import { LetterModifyParagraphForm } from "./LetterModifyParagraphForm.jsx";

/**
 *
 * @param {usrFieldUpdate} Mise à jour des données utilisateur sur la lettre
 * @param {onSubmit} Validation du formulaire
 * @returns Superman
 */
export function LetterUpdatedForm() {
  /* Context */
  const { step } = useContext(ModifyContext);
  const {
    letter_content, // Référence
    letterParagaraphRef, // Ref parent des paragraphes de la lettre de motivation
    modifyParagaraphRef, // Ref input de la modification d'un paragraphe de la lettre de motivation
  } = useContext(ModifyContext);
  // console.log("step", step);
  const details = [
    null,
    null,
    <LetterModifyParagraphForm modifyParagaraphRef={modifyParagaraphRef} />,
  ];

  /* UseRef */
  const addParams = useRef({ param: null }).current;
  const ModifyParams = useRef({ param: null }).current;

  /**
   * Inhiber le comportement par défaut du questionnaire
   * @param {SubmitEvent} e
   */
  function onSubmit(e) {
    e.preventDefault();
  }

  useEffect(
    (_) => {
      if (step === null) return;
      addParams.param = {
        ...!(step > 0),
      };
      ModifyParams.param = {
        ...(step >= letterParagaraphRef.current.childNodes.length - 1 && {
          disabled: true,
        }),
      };
    },
    [step, ModifyParams, addParams, letterParagaraphRef]
  );

  return (
    <form
      method="POST"
      encType="multipart/form-data"
      id="letterForm"
      ref={letter_content}
      onSubmit={onSubmit}
    >
      {!step && (
        <div className="modify-letter-indications">
          <p>Veuillez sélectionner le texte que vous souhaitez modifier.</p>
          <p>
            Cliquer sur <strong>Personnaliser la lettre</strong> pour accèder à
            la finalisation de votre lettre.
          </p>
        </div>
      )}
      {/*TODO : Génère un "0" que je n'arrive pas à enlever sans endommager le textarea modificateur. */}
      {!!step && details[step]}
    </form>
  );
} // End function LetterForm
