import React, { useState } from "react";

import "../scss/modal-box.scss";
import { CrossSVG } from "./svg/CrossSVG";

export const ModalBox = ({
  backgroundnode,
  content,
  overrideCssClass = "",
  parentcallback,
  positionX, // x value of top left corner of this component
  positionY, // y value of top left corner of this component
  ...xprops
}) => {
  /*
    Independant modal box accepting any content, especially a child component.
  */
  const [cssClasses, setCssClasses] = useState(
    overrideCssClass != "" ? overrideCssClass : ["modal-box", "slide-in-right"]
  );
  const backgroundNodeCssClass = "darkening";
  const computedHeight = "calc(100vh - " + positionY + "px)";

  let modalBox = (
      <div
        className={cssClasses.join(" ")}
        style={{
          top: positionY + "px",
          height: computedHeight,
        }}
      >
        <div className="closing-widget" onClick={removeModalBox}>
          <CrossSVG />
        </div>
        <div className="content">{content}</div>
      </div>
    ),
    timer;

  // backgroundnode.classList.toggle(backgroundNodeCssClass);

  /*
    Attempt to turn background's light down as modal box slides in has been a complete failure.
    I couldn't find a decent way to access background and actually change the proper node's properties...
  */
  if (content) {
    // backgroundnode.classList = backgroundNodeCssClass;
    return modalBox;
  }

  /*
  ╔═════════════════════════════════════════════╗
  ║       Slide modal box out to the right      ║
  ╚═════════════════════════════════════════════╝*/
  function removeModalBox(ev) {
    setCssClasses(["modal-box slide-out-right"]);
    timer = window.setTimeout(function () {
      content = null;
      window.clearTimeout(timer);
      parentcallback();
      setCssClasses(["modal-box", "slide-in-right"]);
      // backgroundnode.classList.toggle(backgroundNodeCssClass);
    }, 1200);
  } // end function removeModalBox
}; // end of function component ModalBox
