import { useContext } from "react";
import { ModifyContext } from "../../../pages/LetterPage";

export function CompanyFormEnterprise() {
  const { companyDetails } = useContext(ModifyContext);
  return (
    <div className="empl-enterprise">
      <p className="app-font-size-title2">Chez qui postulez-vous&nbsp;?</p>
      <label htmlFor="enterprise">
        <input
          required
          type="text"
          name="enterprise"
          placeholder="Nom de l'entreprise*"
          autoFocus
          pattern={"[0-9a-zA-ZÀ-ž]"}
          value={companyDetails.enterprise}
        />
      </label>
    </div>
  );
}
