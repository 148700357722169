import React from "react";

/* Custom style */
import "../scss/legal-notices-page.scss";

export const LegalNoticesPage = () => {
  return (
    <div className="fullpage">
      <h1>Mentions Légales</h1>
      <div className="legal-section">
        <p>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi
          n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie
          numérique, dite L.C.E.N., il est porté à la connaissance des
          utilisateurs et visiteurs, ci-après l'"Utilisateur", du site{" "}
          <a
            href="https://victoria.codephenix.fr/"
            target="_blank"
            rel="noreferrer"
          >
            https://victoria.codephenix.fr/
          </a>{" "}
          , ci-après le "Site", les présentes mentions légales. La connexion et
          la navigation sur le Site par l'Utilisateur implique acceptation
          intégrale et sans réserve des présentes mentions légales. Ces
          dernières sont accessibles sur le Site à la rubrique « Mentions
          légales ».
        </p>
      </div>
      <div className="legal-section">
        <h2>Article 1 - L'éditeur</h2>
        <p>
          L'édition du Site est assurée par{" "}
          <a href="https://codephenix.fr" target="_blank" rel="noreferrer">
            Code Phenix
          </a>{" "}
          SAS au capital de 0,1 euros, immatriculée au Registre du Commerce et
          des Sociétés de Paris sous le numéro 849957204 dont le siège social
          est situé au 9 RUE PARROT 75012 PARIS, N° de TVA intracommunautaire :
          FR32849957204 Le Directeur de la publication est Brieuc Le Bars
          ci-après l'"Editeur".
        </p>
      </div>
      <div className="legal-section">
        <h2>Article 2 - L'hébergeur</h2>
        <p>
          L'hébergeur du Site est la société OVH SAS, dont le siège social est
          situé au 2, rue Kellermann, 59100 Roubaix.
        </p>
      </div>
      <div className="legal-section">
        <h2>Article 3 - Accès au site</h2>
        <p>
          Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
          majeure, interruption programmée ou non et pouvant découlant d'une
          nécessité de maintenance. <br />
          En cas de modification, interruption ou suspension du Site, l'Editeur
          ne saurait être tenu responsable.
        </p>
      </div>
      <div className="legal-section">
        <h2>Article 4 - Collecte des données</h2>
        <p>
          Le site est exempté de déclaration à la Commission Nationale
          Informatique et Libertés (CNIL) dans la mesure où il ne collecte
          aucune donnée concernant les utilisateurs. Toute utilisation,
          reproduction, diffusion, commercialisation, modification de toute ou
          partie du Site, sans autorisation de l'Editeur est prohibée et pourra
          entraîner des actions et poursuites judiciaires telles que notamment
          prévues par le Code de la propriété intellectuelle et le Code civil.
        </p>
      </div>
    </div>
  );
};
