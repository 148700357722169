import React from "react";

export function MobileMenuSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3.8805001 3.8806"
      role="img"
      aria-label="Icône menu mobile"
      title="Icône menu mobile"
    >
      <path
        id="path1287"
        style={{
          fill: "#000000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        d="M 1.1759,0 H 0.2352 C 0.1053,0 0,0.1053 0,0.2352 v 0.9407 c 0,0.1299 0.1053,0.2352 0.2352,0.2352 h 0.9407 c 0.1299,0 0.2352,-0.1053 0.2352,-0.2352 V 0.2352 C 1.4111,0.1053 1.3058,0 1.1759,0 Z"
      />
      <path
        id="path1291"
        style={{
          fill: "#000000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        d="M 3.6453,0 H 2.7046 C 2.5747,0 2.4694,0.1053 2.4694,0.2352 v 0.9407 c 0,0.1299 0.1053,0.2352 0.2352,0.2352 h 0.9407 c 0.1299,0 0.2352,-0.1053 0.2352,-0.2352 V 0.2352 C 3.8805,0.1053 3.7752,0 3.6453,0 Z"
      />
      <path
        id="path1295"
        style={{
          fill: "#000000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        d="M 1.1759,2.4694 H 0.2352 C 0.1053,2.4694 0,2.5747 0,2.7046 v 0.9408 c 0,0.1299 0.1053,0.2352 0.2352,0.2352 h 0.9407 c 0.1299,0 0.2352,-0.1053 0.2352,-0.2352 V 2.7046 c 0,-0.1299 -0.1053,-0.2352 -0.2352,-0.2352 z"
      />
      <path
        id="path1299"
        style={{
          fill: "#000000",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        d="M 3.6453,2.4694 H 2.7046 c -0.1299,0 -0.2352,0.1053 -0.2352,0.2352 v 0.9408 c 0,0.1299 0.1053,0.2352 0.2352,0.2352 h 0.9407 c 0.1299,0 0.2352,-0.1053 0.2352,-0.2352 V 2.7046 c 0,-0.1299 -0.1053,-0.2352 -0.2352,-0.2352 z"
      />
    </svg>
  );
} // end function MobileMenuSVG
