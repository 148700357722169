import React from "react";
import { Route, Routes } from "react-router";
// import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';

/*
  Elements disponibles dans react-router-dom :

  AbortedDeferredError, Await, BrowserRouter, Form, HashRouter, Link, MemoryRouter,
  NavLink, Navigate, NavigationType, Outlet, Route, Router, RouterProvider, Routes,
  ScrollRestoration, UNSAFE_DataRouterContext, UNSAFE_DataRouterStateContext,
  UNSAFE_LocationContext, UNSAFE_NavigationContext, UNSAFE_RouteContext, UNSAFE_useRouteId,
  UNSAFE_useScrollRestoration, createBrowserRouter, createHashRouter, createMemoryRouter,
  createPath, createRoutesFromChildren, createRoutesFromElements, createSearchParams,
  defer, generatePath, isRouteErrorResponse, json, matchPath, matchRoutes, parsePath,
  redirect, renderMatches, resolvePath, unstable_HistoryRouter, unstable_useBlocker,
  unstable_usePrompt, useActionData, useAsyncError, useAsyncValue, useBeforeUnload,
  useFetcher, useFetchers, useFormAction, useHref, useInRouterContext, useLinkClickHandler,
  useLoaderData, useLocation, useMatch, useMatches, useNavigate, useNavigation, useNavigationType,
  useOutlet, useOutletContext, useParams, useResolvedPath, useRevalidator, useRouteError,
  useRouteLoaderData, useRoutes, useSearchParams, useSubmit)

*/

import { HomePage } from "./pages/HomePage";

import { IntroductionPage } from "./pages/IntroductionPage";
import { LandingPage } from "./pages/LandingPage";
// import { Login from "./pages/LoginPage";
import { LetterPage } from "./pages/LetterPage";
// import { NewLetter from "./pages/NewLetterPage";
import { QuestionnairePage } from "./pages/QuestionnairePage";
import { LegalNoticesPage } from "./pages/LegalNoticesPage";

export function VictoriaRoutes(props) {
  const routes = [
    {
      path: "/",
      component: <LandingPage />,
    },
    {
      path: "/Home",
      component: <HomePage />,
    },
    {
      path: "/Introduction",
      component: <IntroductionPage />,
    },
    {
      path: "/LegalNotices",
      component: <LegalNoticesPage />,
    },
    // {
    //   path: '/Login',
    //   component: <Login />
    // },
    {
      path: "/Letter",
      component: <LetterPage />,
    },
    {
      path: "/Questionnaire",
      component: <QuestionnairePage />,
    },
  ];

  return (
    <Routes>
      {routes.map((route) => (
        <Route exact path={route.path} key={route.path}>
          <Route exact path={route.path} element={route.component} />
        </Route>
      ))}
    </Routes>
  );
}
