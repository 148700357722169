import React from "react";

/* Custom components */
import { Picture } from "../Picture";
import { VersatileButton } from "../VersatileButton";

/* SVG components */
import { EyeSVG } from "../svg/EyeSVG";

/*
╔═════════════════════════════════════════════╗
║                  Component                  ║
╚═════════════════════════════════════════════╝*/

const EyeData = {
  label: "Œil",
  svg: <EyeSVG />,
};

export function ManageCoveringLetter(props) {
  return (
    <>
      <div className="manage-covering-letter">
        <div className="my-letter"></div>
        <VersatileButton
          content={"Voir ma lettre de motivation"}
          leftIcon={<Picture svg={EyeData.svg} />}
          linkUrl="/Letter"
        />
      </div>
    </>
  );
} // end function Component ManageCoveringLetter
