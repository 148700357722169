/**
 * @file Modification de la lettre de motivation
 * @author Craspouille
 *
 */

import {
  createContext,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/* CSS */
import "../scss/letters-motivation.scss";
import "../scss/spinner.scss";

/* Components */
import { LetterContentUpdated } from "../components/letter/LetterContent/LetterContentUpdated.jsx";
import { CompanyDetails } from "../components/letter/SidePanel/CompanyDetails.jsx";
import { DateDetails } from "../components/letter/SidePanel/DateDetails.jsx";
import { SidePanel } from "../components/letter/SidePanel/SidePanel.jsx";
import { UserDetails } from "../components/letter/SidePanel/UserDetails.jsx";
import { UserInfosSignature } from "../components/letter/SidePanel/UserInfosSignature.jsx";

/* Context */
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext, localStorageKeys } from "../App.jsx";
export const ModifyContext = createContext(null);

/* Custom components */
// import { CrossSVG } from "../components/svg/CrossSVG.jsx";

export function LetterPage() {
  const navigate = useNavigate();
  /*
   ╔═════════════════════════════════════════════╗
   ║                      CONTEXT                ║
   ╚═════════════════════════════════════════════╝*/

  /* Context */
  const {
    appIsMobile,
    appWidth,
    appHeight,
    answers,
    originalLetter,
    setOriginalLetter,
    setModifiedLetter,
    captchaVerified,
  } = useContext(AppContext);

  const letter_content = useRef(null); // Référence des containers
  const letterParagaraphRef = useRef(null); // Référence des containers
  const letterBodyRef = useRef(null); // Référence des containers
  const letterContainerRef = useRef(null); // Référence des containers

  let textArea = useRef(null);

  //Show/Hide SidePanel
  const sidePaneltoClose = useRef();
  const controlWidgetToClose = useRef();
  const panelShow = useRef();

  /* createRef */
  const modifyParagaraphRef = createRef(null);
  const newParagaraphRef = createRef(null);

  const [selection, setSelection] = useState({
    select: null,
    ref: null,
    id: null,
  });

  //SidePanel - Widgets User/Company
  const [canGoNext, setCanGoNext] = useState(false); // Autorisation de passer au formulaire suivant ??!! Booléen
  const [nextStep, setNextStep] = useState(false); // Autorisation de passer à la prochaine étape dans un formilaire - Booléen
  const [panelVisibility, setPanelVisibility] = useState(true); // État de visibilité du panneau sur la gauche

  //   const [letterOriginScale, setLetterOriginScale] = useState(1);
  const [userScale, setUserScale] = useState(null);
  const [letterLeftPos, setLetterLeftPos] = useState(0);
  let [letterTransformOrigin, setLetterTransformOrigin] =
    useState("top center");

  const letterOriginScale = useMemo(() => {
    if (!appIsMobile) return 1;
    //   let ratioW = (appWidth - 30) / letterBodyRef.current.clientWidth;
    //   let ratioH = (appHeight - 45) / letterBodyRef.current.clientHeight;
    let ratioW = appWidth / 794; // 21cm = 794px;
    let ratioH = appHeight / 1123; // 29.7cm = 1123px;
    return Math.min(ratioH, ratioW);
  }, [appIsMobile, appWidth, appHeight]);

  const letterScale = useMemo(() => {
    if (!appIsMobile) return 1;
    if (!userScale) return letterOriginScale;
    return letterOriginScale * userScale;
  }, [letterOriginScale, userScale, appIsMobile]);

  const questionnaire = useMemo(() => {
    const _questionnaire = { questionnaire: [] };
    for (let question of answers) {
      for (let answer of question.answers) {
        _questionnaire["questionnaire"].push({
          question: question,
          answer: answer,
        });
      }
    }
    return _questionnaire;
  }, [answers]);

  useEffect(() => {
    if (!captchaVerified) {
      alert("Veuillez cocher la case 'Je ne suis pas un robot'");
      navigate("/Introduction");
      return;
    }
  }, [captchaVerified, navigate]);
  /*
  ╔═════════════════════════════════════════════╗
  ║                      POST                   ║
  ╚═════════════════════════════════════════════╝*/

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (originalLetter) {
      setLoading(false);
    } else {
      const url = process.env.REACT_APP_BACKEND_BASE_URL + "/generate-letter";
      // console.log(answers);
      axios
        .post(url, questionnaire, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setLoading(false);
          localStorage.setItem(
            localStorageKeys.originalLetter,
            res.data.motivation_letter
          );
          localStorage.setItem(
            localStorageKeys.modifiedLetter,
            res.data.motivation_letter
          );
          setOriginalLetter(
            localStorage.getItem(localStorageKeys.originalLetter)
          );
          setModifiedLetter(
            localStorage.getItem(localStorageKeys.originalLetter)
          );
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [originalLetter, questionnaire, setModifiedLetter, setOriginalLetter]);

  /*
  ╔═════════════════════════════════════════════╗
  ║                    DESIGN                   ║
  ╚═════════════════════════════════════════════╝*/

  // Computer blank page setter

  let percent = function (offset, size) {
    let result = (offset / size) * 100;
    return result;
  };

  /*
  ╔═════════════════════════════════════════════╗
  ║                    WIDGET                   ║
  ╚═════════════════════════════════════════════╝*/

  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem(localStorageKeys.userDetails)) || {
      gender: null,
      firstName: null,
      lastName: null,
      address: null,
      zipcode: null,
      city: null,
      email: null,
      phone: null,
      date: null,
    }
  );

  const [companyDetails, setCompanyDetails] = useState(
    JSON.parse(localStorage.getItem(localStorageKeys.companyDetails)) || {
      //   gender: null,
      //   firstName: null,
      //   lastName: null,
      address: null,
      enterprise: null,
      zipcode: null,
      city: null,
    }
  );

  const canvas = useRef(null); // Zone de signature
  const companyForm = useRef(null); // Référence des containers

  const [blobSignature, setBlobSignature] = useState(
    localStorage.getItem(localStorageKeys.blobSignature)
  ); // État de la signature
  /* Numéro du formulaire à afficher */
  const [step, setStep] = useState(0); // Compteur des étapes d'un formulaire
  /* Formulaire à afficher */
  const [steppers, setSteppers] = useState(0);
  // Used with UserForm component
  function handleClickPanelVisibility(e) {
    e.stopPropagation();
    if (e.nativeEvent.target.closest("[data-step]")) {
      const newStep = parseInt(
        e.nativeEvent.target.closest("[data-step]").dataset.step
      );
      setStep(newStep);
    }
    if (e.nativeEvent.target.closest("[data-steppers]")) {
      const newStepper = parseInt(
        e.nativeEvent.target.closest("[data-steppers]").dataset.steppers
      );
      setSteppers(newStepper);
    }
    setPanelVisibility(true);
  }

  // UseEffect
  useEffect(
    (_) => {
      if (blobSignature) {
        userDetails["signature"] = blobSignature;
      }
    },
    [blobSignature]
  );

  // Utilisateur
  function handleFormFieldUpdate(evt) {
    let re = new RegExp(evt.target.pattern); // Pattern de l'input
    // console.log(evt.target.closest('form').id || evt.target.closest('.date-and-sign'));
    // Vérification du pattern de l'input
    if (re.test(evt.target.value)) {
      // Retire la classe "invalid" si celle-ci est présente
      if (evt.target.classList.contains("invalid"))
        evt.target.classList.remove("invalid");
      // Ajoute la classe "valid" si le pattern est "true" et passe au suivant
      evt.target.classList.add("valid");
      setNextStep(re.test(evt.target.value));
      if (
        evt.target.name === "date" ||
        evt.target.closest("form").id === "usrForm"
      ) {
        // Renseignement de l'objet
        userDetails[evt.target.name] = evt.target.value;
        // Mise à jour du setter
        setUserDetails({ ...userDetails });
        localStorage.setItem(
          localStorageKeys.userDetails,
          JSON.stringify({ ...userDetails })
        );
      } else {
        // Renseignement de l'objet
        companyDetails[evt.target.name] = evt.target.value;
        // Mise à jour du setter
        setCompanyDetails({ ...companyDetails });
        localStorage.setItem(
          localStorageKeys.companyDetails,
          JSON.stringify({ ...companyDetails })
        );
      }
    } else {
      // Retirer classe "valid" si celle-ci est présente
      if (evt.target.classList.contains("valid"))
        evt.target.classList.remove("valid");
      // Ajoute la classe "valid" si le pattern est "false" et passe au suivant
      evt.target.classList.add("invalid");
      setNextStep(re.test(evt.target.value));
    }
  }

  // UseEffect
  useEffect(
    (_) => {
      if (!blobSignature) return;
      userDetails["signature"] = blobSignature;
    },
    [blobSignature]
  );

  useEffect(
    (_) => {
      if (appIsMobile && letterBodyRef) {
        let letterWidth = letterBodyRef.current.clientWidth * letterScale;
        if (letterWidth < appWidth) {
          setLetterTransformOrigin("top left");
          setLetterLeftPos((appWidth - letterWidth) / 2);
          return;
        }
      }
      setLetterLeftPos(0);
    },
    [
      userScale,
      letterOriginScale,
      appIsMobile,
      letterScale,
      letterBodyRef,
      appWidth,
    ]
  );

  function evidenceZoom(e) {
    if (!appIsMobile) return;
    setLetterTransformOrigin(
      `${percent(e.nativeEvent.clientX, appWidth)}% 
      ${percent(e.nativeEvent.clientY, appHeight)}%`
    );
    setUserScale(1.1);
  }

  /* FUNCTIONS */

  function nextForm() {
    if (steppers <= 4) {
      setSteppers(steppers + 1);
      setStep(0);
      setCanGoNext(false);
    }
    if (steppers === 0) setCanGoNext(false);
  }

  function prevForm() {
    if (steppers > 0) setSteppers(steppers - 1);
  }

  function validateLetter(ev) {
    /* Handler */
    ev.preventDefault();
    console.log("DEBUG: activé handler validateLetter");
  }

  function handleClosePanelVisibility(e) {
    setPanelVisibility(false);
  } // End function handleClosePanelVisibility

  /* Adaptation automatique des textareas */
  /* Automatic adaptation of textareas */
  /**
   *
   * @param {HTMLInputElement} element
   */
  function auto_grow(element) {
    // Réinitialisation de la hauteur
    // Height reset
    element.style.height = `.375rem`;
    // Adaptation de la hauteur
    // Height adaptation
    element.style.height = `${element.scrollHeight}px`;
  }

  /*
  ╔═════════════════════════════════════════════╗
  ║                   Rendering                 ║
  ╚═════════════════════════════════════════════╝*/

  return (
    <ModifyContext.Provider
      key={"context-modify"}
      value={{
        /* Events */
        handleClosePanelVisibility,
        // handleLetterContentUpdate,
        /* Ref formulaire */
        letter_content,
        modifyParagaraphRef,
        newParagaraphRef,
        letterParagaraphRef,
        letterContainerRef,
        letterBodyRef,
        textArea,
        /* UseState */
        panelVisibility,
        setPanelVisibility,
        setSelection,
        selection,
        /* function */
        auto_grow,

        //FinalizeLetter UserInfosDetails
        userDetails,
        setUserDetails,
        step,
        setStep,
        steppers,
        setSteppers,
        handleClickPanelVisibility,
        companyDetails,
        setCompanyDetails,
        blobSignature,
        setBlobSignature,

        //FinalizeLetter SideBarForm
        handleFormFieldUpdate,
        canGoNext,
        setCanGoNext,
        nextStep,
        setNextStep,
        prevForm,
        nextForm,
        canvas,
        companyForm,
        validateLetter,
      }}
    >
      <div className="page-body">
        <SidePanel visibility={panelVisibility} updatedCoordsCallback={null} />
        <div className="modify-letter" ref={letterContainerRef}>
          <div
            className="letter-body"
            ref={letterBodyRef}
            style={{
              transform: `scale(${letterScale})`,
              //   transformOrigin: letterTransformOrigin,
              left: `${letterLeftPos}px`,
            }}
            // onPointerDown={evidenceZoom}
          >
            {!!loading ? (
              <div className="container_loader">
                <p>Votre lettre est en cours de génération</p>
                <div className="loader"></div>
              </div>
            ) : (
              <div id="letter-zone">
                <UserDetails />
                <DateDetails />
                <CompanyDetails />
                <LetterContentUpdated />
                <UserInfosSignature />
              </div>
            )}
          </div>
        </div>
      </div>
    </ModifyContext.Provider>
  );
}
