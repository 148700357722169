import React from "react";

export function HelpButtonSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.225 7.4083328"
      role="img"
      aria-label="Bouton d'aide"
      title="Bouton d'aide"
      className="help-button"
    >
      <path
        d="m 0.176389,3.704167 c 0,-1.948337 1.57944,-3.527777 3.527778,-3.527777 h 14.816666 c 1.948337,0 3.527778,1.57944 3.527778,3.527777 v 0 c 0,1.948338 -1.579441,3.527778 -3.527778,3.527778 H 3.704167 c -1.948338,0 -3.527778,-1.57944 -3.527778,-3.527778 z"
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        id="Fond"
      />
      <path
        d="M 3.704167,0.352778 H 18.520833 V 0 H 3.704167 Z M 18.520833,7.055555 H 3.704167 v 0.352778 h 14.816666 z m -14.816666,0 c -1.850921,0 -3.351389,-1.500468 -3.351389,-3.351388 H 0 C 0,5.749921 1.658413,7.408333 3.704167,7.408333 Z M 21.872222,3.704167 c 0,1.85092 -1.500468,3.351388 -3.351389,3.351388 v 0.352778 c 2.045754,0 3.704167,-1.658412 3.704167,-3.704166 z M 18.520833,0.352778 c 1.850921,0 3.351389,1.500468 3.351389,3.351389 H 22.225 C 22.225,1.658412 20.566587,0 18.520833,0 Z M 3.704167,0 C 1.658412,0 0,1.658412 0,3.704167 h 0.352778 c 0,-1.850921 1.500468,-3.351389 3.351389,-3.351389 z"
        style={{
          fill: "#303030",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        id="Contour"
      />
      <path
        d="M 4.903611,4.974167 H 5.326944 V 4.550834 H 4.903611 Z M 5.115278,1.587501 c -1.1684,0 -2.116667,0.948266 -2.116667,2.116666 0,1.1684 0.948267,2.116667 2.116667,2.116667 1.1684,0 2.116666,-0.948267 2.116666,-2.116667 0,-1.1684 -0.948266,-2.116666 -2.116666,-2.116666 z m 0,3.81 c -0.93345,0 -1.693334,-0.759884 -1.693334,-1.693334 0,-0.93345 0.759884,-1.693333 1.693334,-1.693333 0.93345,0 1.693333,0.759883 1.693333,1.693333 0,0.93345 -0.759883,1.693334 -1.693333,1.693334 z m 0,-2.963334 c -0.467784,0 -0.846667,0.378884 -0.846667,0.846667 h 0.423333 c 0,-0.232833 0.1905,-0.423333 0.423334,-0.423333 0.232833,0 0.423333,0.1905 0.423333,0.423333 0,0.423333 -0.635,0.370417 -0.635,1.058333 h 0.423333 c 0,-0.47625 0.635001,-0.529166 0.635001,-1.058333 0,-0.467783 -0.378884,-0.846667 -0.846667,-0.846667 z"
        style={{
          fill: "#303030",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        id="Interrogation"
      />
      <path
        d="m 8.810488,5.115286 z m 0.148828,0 1.035595,-2.982764 h 0.531234 V 2.727835 H 10.344244 L 9.614573,5.115286 Z M 9.513287,4.391816 9.666249,3.924661 h 1.378728 l 0.152962,0.467155 z m 1.595768,0.72347 -0.727604,-2.387451 V 2.132522 h 0.347266 l 1.037663,2.982764 z m 0.802019,0 z m 0.361734,0 V 2.132522 h 0.624252 v 2.982764 z m 0.985987,0 z m 0.361735,0 V 2.132522 h 1.174088 c 0.296279,0 0.54846,0.05788 0.756543,0.173633 0.208084,0.114377 0.367248,0.281119 0.477491,0.500228 0.110243,0.219108 0.165364,0.48438 0.165364,0.795817 v 0.0041 c 0,0.321083 -0.05512,0.593934 -0.165364,0.818555 -0.108866,0.22462 -0.26734,0.396186 -0.475423,0.514697 -0.208084,0.117133 -0.460954,0.1757 -0.758611,0.1757 z M 14.244781,4.600588 H 14.6954 c 0.184657,0 0.341064,-0.0379 0.469222,-0.113688 0.128157,-0.07579 0.225309,-0.186724 0.291455,-0.332796 0.06752,-0.14745 0.101285,-0.326595 0.101285,-0.537435 v -0.0041 c 0,-0.205328 -0.03445,-0.37965 -0.103352,-0.522966 C 15.38649,2.944909 15.287956,2.835355 15.15842,2.760941 15.030263,2.685151 14.875923,2.647253 14.6954,2.647253 h -0.450619 z m 2.180746,0.514698 z m 0.361735,0 V 2.132522 h 1.976106 v 0.514697 h -1.351855 v 0.719336 h 1.275374 v 0.479558 h -1.275374 v 0.754475 h 1.351855 v 0.514698 z"
        style={{
          fill: "#303030",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.35277778,
        }}
        id="Texte"
      />
    </svg>
  );
}
