import React, { useState } from "react";
// import { ReactDOM } from "react";

import { NavLink } from "react-router-dom";
import Typewriter from "typewriter-effect";
// import Introduction from "./IntroductionPage.jsx";

/* App Custom Component(s) */
import { Picture } from "../components/Picture.jsx";
import { RightArrowSVG } from "../components/svg/RightArrowSVG.jsx";

/* App Custom Stylesheet(s) */
import "../scss/landing-page.scss";

export const LandingPage = (props) => {
  const [skip, setSkip] = useState({ state: false });

  const myWriterEffect = (
    <Typewriter
      onInit={(typewriter) => {
        typewriter
          .changeDelay(65)
          .typeString(
            "Bonjour, je suis VictorIa,<br/>votre conseillère<br/>générée par une IA..."
          )
          .pauseFor(1500)
          .deleteAll(30)
          .pauseFor(1500)
          .typeString(
            "Je vous invite à activer<br/>le son de votre téléphone<br/>et à me suivre..."
          )
          .pauseFor(3000)
          .callFunction(skipIntro)
          .start();
      }}
    />
  );

  function skipIntro(st) {
    if (skip.state) {
      return;
    }
    window.location.href = "/Introduction/";
  }

  function onSkipEvent() {
    skip.state = true;
  }

  return (
    <div className="landing-page fullpage">
      <div className="widecontent">
        <div className="welcome-text" id="typewriter-zone">
          {myWriterEffect}
        </div>
      </div>
      <div className="controls">
        <div className="bottom-link">
          <NavLink onClick={onSkipEvent} to="/Introduction">
            <span>Passer</span>
            <Picture
              image={{
                alt: "Icône suivant",
                src: require("../assets/pics/icons/icon_suivant.png"),
              }}
              label={"Suivant"}
              svg={<RightArrowSVG />}
            />
          </NavLink>
        </div>
      </div>
    </div>
  );
};
