import React, { useState } from "react";

/* Custom */
import { PictureBox } from "./PictureBox.jsx";
/* Style */
import "../scss/picture-link.scss";

export function PictureLink({
  gotourl,
  image,
  label,
  overrideCssClass = "",
  svg,
  ...extras
}) {
  const [cssClasses, setCssClasses] = useState(
    overrideCssClass != "" ? overrideCssClass : ["picture-link"]
  );

  return (
    <>
      <div className={cssClasses} {...extras}>
        <a href={gotourl}>
          <PictureBox image={image} svg={svg} />
          <label>{label}</label>
        </a>
      </div>
    </>
  );
} // end function component PictureLink
