import React from "react";

export function RightArrowSVG(props) {
  /*
    Svg turned into a component
  */

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4.14534 3.42851"
      role="img"
      aria-label="Icône suivant"
      title="Icône suivant"
      className="fleche"
      style={props.style}
    >
      <g>
        <path
          id="path334"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 0.35277778,
          }}
          d="m 4.14534,1.71428 c 0,0.0864 -0.0314,0.15893 -0.0937,0.21773 l -1.40836,1.4056 c -0.0625,0.0606 -0.13229,0.0909 -0.20945,0.0909 -0.0845,0 -0.15342,-0.0266 -0.20672,-0.0799 -0.0533,-0.0533 -0.0799,-0.12035 -0.0799,-0.20119 0,-0.0423 0.007,-0.0808 0.0219,-0.11576 0.0166,-0.0367 0.0386,-0.0671 0.0661,-0.0909 L 2.65135,2.51633 3.45889,1.78321 3.56913,1.9596 2.74783,2.012 H 0.30319 C 0.21129,2.012 0.13781,1.9845 0.0827,1.9293 0.0276,1.8742 0,1.80252 0,1.71433 0,1.62793 0.0275,1.55723 0.0827,1.50211 0.1378,1.44701 0.21132,1.41941 0.30319,1.41941 H 2.74783 L 3.56913,1.47181 3.45889,1.6482 2.65135,0.91509 2.23521,0.49065 c -0.0275,-0.0257 -0.0496,-0.056 -0.0661,-0.091 -0.0148,-0.0349 -0.0219,-0.0744 -0.0219,-0.11851 0,-0.079 0.0265,-0.14516 0.0799,-0.19844 C 2.28041,0.0276 2.34931,0 2.43383,0 2.51103,0 2.5808,0.0303 2.64328,0.0909 l 1.40836,1.4056 c 0.0625,0.0606 0.0937,0.13321 0.0937,0.21773 z"
        />
      </g>
    </svg>
  );
} // end function NextSVG
