import React from "react";

import { Picture } from "../components/Picture";

/**
 * @file ButtonMenuItem with possible image
 * @author Cody
 */

/**
 * @typedef {Object} ReactProps
 * @property {String} imageSrc
 * @property {String} imageAlt
 * @property {Function} onclickcallback - external onClick handler
 */

/**
 * @param {ReactProps} props
 * @returns NavBarItem
 */

export function NavBarItem({
  cssClass,
  image,
  label,
  svg,
  url,
  onclickcallback,
  ...rest
}) {
  /*
    Render a regular nav bar item's image (jpeg, png, ...) or a SVG.
    If both image and SVG are given the last prevails.
  */
  return (
    <>
      <nav
        className={["navbar-item", cssClass].join(" ")}
        onClick={onclickcallback ? onclickcallback : null}
        aria-current={window.location.href.endsWith(url) ? "true" : "false"}
      >
        <div className="navbar-item-icon" {...rest}>
          <Picture image={image} label={label} svg={svg} />
        </div>
        <label className="navbar-item-label">{label}</label>
      </nav>
    </>
  );
} // end function NavBarItem
