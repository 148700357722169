/**
 * @file Composant Proposition
 * @author Prof. Chaos
 */

import React, { Fragment, useContext, useEffect } from "react";

/* Custom components */
import { QuestionnaireFormFreeTextInput } from "./QuestionnaireFormFreeTextInput";
import { QuestionnaireFormMutexInput } from "./QuestionnaireFormMutexInput";

/* App Context */
import { AppContext } from "../../App";

export const QuestionnaireFormInput = function ({
  choice,
  selectChoice,
  isUserChecked,
  myQuestion,
  userInputMode,
  setGlobalHelpParagraphs,
}) {
  const { appIsMobile, appWidth } = useContext(AppContext);

  useEffect((_) => {
    // Selectionner le choix si :
    // - c'est un choix par défaut
    // - c'est un choix déjà sélectionné par l'utilisateur et qu'il est valide
    //
    // Remarque : Un choix valide sous entend que c'est...
    // - soit un choix simple
    // - soit un choix personnalisé correctement rempli par l'utilisateur
    selectChoice(
      choice,
      (choice.isValid && isUserChecked) ||
        myQuestion.isValidByDefault ||
        myQuestion.isDirectInput ||
        false
    );
    if (myQuestion.isDirectInput && choice.helpParagraphs) {
      setGlobalHelpParagraphs(choice.helpParagraphs);
    }
  }, []);

  if (userInputMode && userInputMode !== choice) return;

  return (
    <Fragment>
      {!userInputMode && (
        <QuestionnaireFormMutexInput
          choice={choice}
          isUserChecked={isUserChecked}
          selectChoice={selectChoice}
        />
      )}
      {userInputMode && (
        <QuestionnaireFormFreeTextInput
          canClose={!myQuestion.isDirectInput}
          choice={choice}
          isUserChecked={isUserChecked}
          rows={choice.rows || appIsMobile ? 20 : 12}
          selectChoice={selectChoice}
        />
      )}
    </Fragment>
  );
};
